import React, { Component } from 'react';
import { getUrlFromBaseAPI, getAPIData } from '../../../util/lib';

const FooterRequest = (Comp) => {
	class FooterRequestComp extends Component {
		state = {
			data: ''
		}

		componentDidMount() {
			const footerURL = getUrlFromBaseAPI('footer');
			console.log("footer url:", footerURL);
			if (footerURL) {
				getAPIData(footerURL, {}, (response = {}) => {
					const data = response.data || '',
						meta = response.meta || {},
						layout = response.layout || 1;

					this.setState({ data, meta, layout });
				});
			}
		}

		render() {
			const footerData = this.state.data,
				footerMeta = this.state.meta,
				footerLayout = this.state.layout;
			//const footerData = resp.data; //TUEX DEUX
			return (footerData ? <Comp {...{ ...this.props, footerData, footerMeta, footerLayout }} /> : null);
		}
	};

	return FooterRequestComp;
}

export default FooterRequest;