import React from 'react';

import '../../scss/earth/SocialLinksE.scss';

const SocialLinksE = (props = {}) => {
	const widget = props.widget || {},
		data = widget.data || {},
		links = data.links || '';
		
		
		console.log(data);

	return (<div className="social-links container bottom-line">
			<div className="widget-title">
				<h2>{data.title || ''}</h2>
			</div>
			<div className="all-links d-flex flex-sm-row">
				{Array.isArray(links) && links.map((item = {}, index) => {
					const iData = item.data || {},
						linkObj = iData.link || {},
						linkURL = linkObj.url || '',
						target = linkObj.target || '_self',
						icon = iData.icon || 'dflt';

					if (item.type === 'Link') {
						return (
							<a key={'nv' + index} href={linkURL} target={target} className={'icon-link social-icon ' + icon}>
								{iData.title || ''}
							</a>
						);
					}
					return false;
				})}
			</div>
		</div>);
};

export default SocialLinksE;