import $, { get } from 'jquery';
import { Redirect } from "react-router-dom";
import React from 'react';

import 'noty/lib/noty.css';
// import 'noty/lib/themes/relax.css';
import 'noty/lib/themes/metroui.css';
import 'animate.css/animate.min.css';
import Noty from 'noty';
import { post } from './fetchRequest';
import { getApiDataV2 } from './apiUtils';
import { isToeknAvailable } from './authUtils';
import { processBeacons, parsePlayerBeacons } from './beaconUtils';

export const arrayToChunks = (array, chunk = 4) => {
	var i, j, temparray, groupedCards = [];

	for (i = 0, j = array.length; i < j; i += chunk) {
		temparray = array.slice(i, i + chunk);
		groupedCards.push(temparray);
	}

	return groupedCards;
}

export const isIE = () => {
	if (
		navigator.appName === 'Microsoft Internet Explorer'
		|| !!(navigator.userAgent.match(/Trident/)
			|| navigator.userAgent.match(/rv:11/))
		|| (typeof window.$.browser !== "undefined" && window.$.browser.msie === 1)
	) {
		console.log("Please dont use IE.");
		return true;
	} else {
		return false;
	}
}

export const getRelativeUrl = (url) => {
	var urldetails = document.createElement('a');
	urldetails.href = url;
	if (isIE()) {
		return '/' + urldetails.pathname;
	} else {
		return urldetails.pathname;
	}
}

export const parseAPIResponse = (data = {}) => {
	return data.response || {};
}


export const showNotification = (data = {}) => {
	new Noty({
		theme: 'metroui',//s
		type: data.type ? data.type : 'error',
		layout: 'topRight',
		text: data.text,
		timeout: 4000,
		closeWith: ['click', 'button'],
		animation: {
			open: 'animated fadeInUp',
			close: 'animated fadeOutUpBig'
		}
	}).show();
}

export const getAPIData = (url, data, callback) => {
	console.log("calling:", url);

	let user_details = getUserDetails();
	const postData = { ...data },
		vt_auth_token = getL('vt-auth-token') || {},
		{ token = "" } = vt_auth_token || {};

	postData.user_key = getUserKey();
	postData.app_version = getEnvVar('REACT_APP_API_VER') || '2'; //{app_version: '1.3.0'}

	if (!postData.user_id && user_details !== false) {
		// console.log(user_details.id + ' ' + getL('webview_user_id')) // TODO ASYNC
		postData.user_id = user_details.id || getL('webview_user_id');
	}

	if (url.indexOf("ott/payment") !== -1 && url.indexOf("&planid=") === -1) {

		const planID = getL('payment_plan_id');
		const content_show_id = getL('show_id') || '';

		if (planID) {
			postData.planid = planID;
			postData.content_show_id = content_show_id;
		}

	}
	if (url.indexOf("ott/stripePayment") !== -1 || url.indexOf("ott/payment") !== -1) {
		const videoID = getL('payment_plan_video_id'),
			start_date = getL('start_date') || '';

		if (videoID) {
			postData.video_id = videoID;
		} else {
			delete postData.video_id
		}
		if (start_date) {
			postData.start_date = start_date;
		}
	}

	if (getEnvVar('REACT_APP_USE_NEW_LOGIN')) {
		return getApiDataV2(url, postData, callback);
	}

	post(url, postData).then((response = {}) => {
		const parsedResponse = parseAPIResponse(response);
		callback(parsedResponse);
	}).catch((exception = '') => {
		console.log('getApiData Failed:', exception);
	});
}

export const getUserDetails = () => {
	var user = getL('user');

	if (user && typeof (user) === 'object' && user.id) {
		return user;
	} else {
		clearL('user');
		return false;
	}
}

export const setUserDetails = (user = {}) => {
	if (user.user_id) {
		const active_services = user.user_active_services.map(function (a) {
			return a.plan_id
		});

		setL('user', {
			id: user.user_id,
			name: user.user_name,
			email: user.user_email,
			phone: user.user_phone,
			active_services,
		});
	} else {
		return false;
	}
}

export const clearUserDetails = () => {
	resetUserKey();

	clearL('user');
	clearL('payment_plan_id');
	clearL('payment_plan_course_id');
	clearL('payment_plan_id_2'); //Arun - payment_plan_id_2 is an alternative to store plan id for Master Class
	clearL('payment_plan_video_id');
	clearL('deviceConnectFlow');
	clearL('start_date');
	clearL('show_id');
	clearL('category_guid');
	clearL('expert_id');
	clearL('class');
}

export const isLoggedIn = () => {
	const user = getUserDetails(),
		tokenAvailable = isToeknAvailable();

	if ((user && user.id) || tokenAvailable) {
		return true;
	} else {
		return false;
	}
}

export const loginRequiredRedirect = () => {
	if (isLoggedIn()) {
		return;
	} else {
		return <Redirect push to='/login' />;
	}
}

export const redirectTo = ($url) => {
	return <Redirect push to={$url} />;
}

export function createLoginSession(userResponse = {}, redirectObj = {}) {
	const active_purchase_flow_plan_id = getL('payment_plan_id'),
		deviceConnectFlow = getL('deviceConnectFlow'),
		postLoginRedirectUrl = getL('postLoginRedirectUrl'),
		destinationPagePath = getL('destination_page_path'),
		redirectURL = redirectObj.path || '';

	console.log('login session');
	console.log(redirectObj);
	setUserDetails(userResponse.data || {});

	console.log('FF@!', postLoginRedirectUrl, destinationPagePath);

	if (redirectURL) {
		window.location.href = redirectURL;
	} else if (deviceConnectFlow) {
		window.location.href = '/connect';
	} else if (active_purchase_flow_plan_id) {
		window.location.href = '/payment';
	} else if (postLoginRedirectUrl) {
		clearL('postLoginRedirectUrl');
		var user_details = getUserDetails();
		if (postLoginRedirectUrl === '/plans' && user_details.active_services.length > 0) {
			window.location.href = '/';
		} else {
			window.location.href = postLoginRedirectUrl;
		}
	} else if (destinationPagePath) {
		console.log('<><><>', destinationPagePath);
		window.location.href = destinationPagePath;
	} else {
		window.location.href = '/';
	}
}

export function getUrlFromBaseAPI(key) {
	var baseAPIs = getLC('baseAPIStore');

	if (typeof baseAPIs[key] !== "undefined") {
		return baseAPIs[key];
	} else {
		console.log('BASE ER: ' + key + ' Not found');
		return false;
	}
}

export const setBaseAPIs = (data = {}, callback = () => { }) => {
	const baseAPIs = getLC('baseAPIStore'),
		baseURL = data.base_apis || getAPIURL('base'),
		routeURL = data.routing_api || getAPIURL('route');

	if (baseAPIs) {
		callback();
	} else {
		getAPIData(baseURL, {}, (baseResponse) => {
			setLC('baseAPIStore', { ...baseResponse, mutated_route_api_url: routeURL }, 1 * 60 * 1); // one hours cache only 
			setLC('refresh-token', baseResponse.refresh_token, 1 * 60 * 60 * 24 * 30); // one month cache only')
			callback();
		});
	}
};

export function getSettingsAPI() {
	var settingsApiUrl = getWebAPIURL('getWebSettings');

	var settings = getLC('settingsAPIStore');

	if (settings === false) {
		console.log('fetch settings');
		return fetch(settingsApiUrl)
			.then(validateFetchResponse)
			.then(readFetchResponseAsJSON)
			.then((resp) => {
				setLC('settingsAPIStore', resp.data, 1 * 60 * 1); // 1 hours cache only
				return resp.data;
			});
	} else {
		// console.log('have settings in cache');
		return new Promise((resolve) => {
			resolve(settings)
		});
	}
}

export function getAPIURL(api) {
	var domain = getEnvVar('REACT_APP_API_DOMAIN');
	var app_key = getEnvVar('REACT_APP_APP_KEY');
	var app_id = getEnvVar('REACT_APP_APP_ID');

	if (!domain || !app_key || !app_id) {
		console.log('ENV ER: not set');
		return '';
	}

	return domain + '/v1/ott/' + api + '/' + app_key + '/' + app_id;
}

export function getWebAPIURL(api) {
	var domain = getEnvVar('REACT_APP_WEB_API_DOMAIN');
	var app_key = getEnvVar('REACT_APP_APP_KEY');
	var app_id = getEnvVar('REACT_APP_APP_ID');

	if (!domain || !app_key || !app_id) {
		console.log('ENV ER: not set');
		return '';
	}

	return domain + '/v1/ott/' + api + '/' + app_key + '/' + app_id;
}

function validateFetchResponse(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
}
function readFetchResponseAsJSON(response) {
	return response.json();
}

export function getEnvVar(key) {
	// console.log('****************');
	// console.log(process);
	// console.log(process.env);
	// console.log('****************');
	if (typeof process.env[key] !== 'undefined') {
		return process.env[key];
	}
	return false;
}

export function getLC(key) {
	var now = Date.now();
	var expiresIn = getL(key + '_expiresIn');
	if (expiresIn === undefined || expiresIn === null) {
		expiresIn = 0;
	}

	if (expiresIn < now) {
		clearLC(key);
		return false;
	} else {
		return getL(key);
	}
}

export function setLC(key, value, expires = null) {
	// Expects expires in Minutes
	if (expires === undefined || expires === null) {
		expires = (1 * 60); // Default 1 hour
	} else {
		expires = Math.abs(expires);
	}
	var now = Date.now();
	var schedule = now + expires * 1000 * 60;

	setL(key, value);
	setL(key + '_expiresIn', schedule);
}

export function clearLC(key) {
	clearL(key);
	clearL(key + '_expiresIn');
}

export function getL(key) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '',
		data = localStorage ? localStorage.getItem(key) : null;

	if (data !== null) {
		return JSON.parse(data);
	} else {
		return false;
	}
}

export function setL(key, value) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '';

	if (localStorage) {
		localStorage.setItem(key, JSON.stringify(value));
	}
}

export function clearL(key) {
	const win = (typeof window === 'object') ? window : {},
		localStorage = win.localStorage || '';

	if (localStorage) {
		localStorage.removeItem(key);
	}

}

export const isServer = () => {
	if (typeof window === "undefined" || window === null) {
		return true;
	} else {
		return false;
	}
}

export const isClient = () => {
	return !isServer();
}

export const parseQryString = (qry) => {
	var qry_obj = {};
	if (qry.substr(0, 1) === '?') {
		qry = qry.substr(1);
	}
	var items = qry.split('&');
	items.map((it) => {
		var para = it.split('=');
		qry_obj[para[0]] = para[1];
		return false;
	});
	return qry_obj;
}


function toHex(text) {
	var hash = 0;
	if (text.length === 0) return hash;
	for (var i = 0; i < text.length; i++) {
		hash = text.charCodeAt(i) + ((hash << 5) - hash);
		hash = hash & hash;
	}
	// var color = '#';
	var color = '';
	for (i = 0; i < 3; i++) {
		var value = (hash >> (i * 8)) & 255;
		color += ('00' + value.toString(16)).substr(-2);
	}
	return color;
}

export const makeId = () => {
	var id = 'D-' + new Date().getTime() + '-';
	var charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (var i = 0; i < 4; i++) {
		id += charSet.charAt(Math.floor(Math.random() * charSet.length));
	}
	return id;
}

export const getRandomArbitrary = (min = 10000, max = 99999) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min;
}

const USER_KEY = 'user_key',
	makeUserKey = (keyLength = 14) => {
		const pKeyLength = parseInt(keyLength) || 14,
			ranNum = Math.floor(getRandomArbitrary(pKeyLength <= 4 ? 100 : 1, Math.pow(10, pKeyLength) - 1)),
			ranNumStr = String(ranNum),
			ranNumLength = ranNumStr.length,
			zeroString = String(Math.pow(10, pKeyLength - ranNumLength)).substr(1);

		return zeroString + ranNumStr;
	},
	resetUserKey = () => {
		// clear and reser user key
		clearL(USER_KEY);
		getUserKey();
	};

export const getUserKey = () => {
	const userKey = getL(USER_KEY) || makeUserKey();

	setL(USER_KEY, userKey);

	return userKey;
}

export const getThemeID = () => {
	const themeID = parseInt(getEnvVar('REACT_APP_THEME') || 1) || 1;

	return themeID;
}


export const scrollToTop = () => {
	window.scrollTo(0, 0);
}
