import 'bootstrap/dist/css/bootstrap.min.css';
// import Popper from 'popper.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

import React, { Component } from 'react';
import { Route } from "react-router-dom";
// import $ from 'jquery';

import { Helmet } from "react-helmet";

import './../scss/App.scss';

import VentunoPlayer from './videoPlayer/VentunoPlayer';
import Page from './Page';
import ShoppingCartManager from './ShoppingCartManager';
import GATracker from './trackers/GATracker';
import FBPixelTracker from './trackers/FBPixelTracker';
import { getEnvVar, getAPIData, getL, parseQryString, setUserDetails, clearUserDetails, setL, isClient, makeId, setBaseAPIs, getSettingsAPI, getThemeID } from '../../util/lib';
import { rgbHex2rgbaHex, changeColorLightness } from '../../util/colorUtils.js';
import Footer from './Footer';
import MasterLoader from './factory/MasterLoader';
import FooterE from './earth/FooterE';
import FooterJ from './jupiter/FooterJ';
import HeaderManager from './header/HeaderManager';
import HeaderMaster from './header/HeaderMaster';
import ComingSoon from './comingsoon/ComingSoon';
import HybridFooter from './hybrid/footer/HybridFooter';
import RouterWrapper from './router/RouterWrapper';
import GA4Tracker from './trackers/GA4Tracker';
import { cacheRefteshTokenApi } from '../../util/authUtils';

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			videoKey: "",
			videoTargetid: "",
			videoPreload: false,
			videoPageTakeOver: false,
			limitedViewing: false,
			error: null,
			isLoaded: false,
			page: null,

			manifest: null,
			base_apis: null,
			routing_api: null,
			home_page: null,
			settingsData: null,
			appStatus: getEnvVar('REACT_APP_STATUS')
		};

		// TODO move to onMount
		if (isClient()) {
			const currentLocation = this.props.loc || '',
				isWebview = currentLocation && currentLocation.search.indexOf('webview') !== -1;

			if (isWebview) {
				setL('webview', true);
			}
			if ((isWebview) && (currentLocation && currentLocation.search.indexOf('user_id') !== -1)) {
				const usr = parseQryString(currentLocation.search);
				if (parseInt(usr.user_id) > 0) {
					setL('webview_user_id', usr.user_id);
					getAPIData(process.env.REACT_APP_PROFILE_API_PATH, {
						'user_id': usr.user_id
					}, (response = {}) => {
						const rspData = response.data || {},
							widgets = rspData.widgets || [],
							widget = widgets.length ? widgets[0] || {} : {},
							widgetData = widget.data || {},
							user = widgetData.user;

						setUserDetails(user.data || {});
					})
				} else {
					clearUserDetails();
				}
			}
		}

		this.handleAPIResponse = this.handleAPIResponse.bind(this);

		this.setVideoDetails = (videoDetails = {}) => {
			const videoKey = videoDetails.video_key || "",
				videoId = videoDetails.video_id || 0;

			console.log('SETVID + app', videoDetails);
			console.log('SETVID + app', videoKey);
			console.log('SETVID + app', this.state.videoKey);

			if (videoKey !== this.state.videoKey) {
				this.setState({
					videoId,
					videoKey,
					videoTargetid: videoDetails.video_targetid || "",
					videoPreload: videoDetails.video_preload || false,
					videoPageTakeOver: videoDetails.video_pageTakeOver || false,
					limitedViewing: videoDetails.video_limitedViewing || false,
					resume: videoDetails.video_resume || false,
					forceBegin: videoDetails.video_forceBegin || false
				});
			}
		}
	}

	render() {
		const state = this.state,
			appStatus = this.state.appStatus;
		if (appStatus === 'comingsoon') {
			return <ComingSoon />
		} else if (!state.settingsData) {
			return (null);
		} else {
			const settingsData = state.settingsData || {},
				defaultTitle = settingsData && settingsData.page_title ? settingsData.page_title : '',
				headerTextColor = settingsData.header_text_color || settingsData.primary_color,
				surfaceEmphasisHigh = settingsData.vtss_on_surface_emphasis_high || settingsData.primary_color,
				surfaceEmphasisMedium = settingsData.vtss_on_surface_emphasis_medium || settingsData.secondary_color || surfaceEmphasisHigh,
				brandPrimary = settingsData.vtss_brand_primary || settingsData.accent_color,
				brandSecondary = settingsData.vtss_brand_secondary || '',
				buttonColor = brandSecondary || settingsData.button_color || brandPrimary,
				buttonTextColor = settingsData.vtss_on_brand_emphasis_high || settingsData.button_text_color || surfaceEmphasisHigh,
				backgroundBaseColor = settingsData.vtss_background_base || settingsData.background_color,
				surfacePrimary = settingsData.vtss_surface_primary || settingsData.header_color,
				surfaceSecondary = settingsData.vtss_surface_secondary || settingsData.vtss_surface_primary || settingsData.header_color,
				vhlHeaderColor = settingsData.vhl_header_color || brandPrimary,
				warningColor = settingsData.vtss_warning_color || brandPrimary,
				socialLoginFacebookBtnBgColor = settingsData.facebook_login_button_color || '4866AB',
				socialLoginFacebookBtnTxtColot = settingsData.facebook_login_text_color || settingsData.primary_color,
				socialLoginGoogleBtnBgColor = settingsData.google_login_button_color || 'dd4b39',
				socialLoginGoogleBtnTxtColot = settingsData.google_login_text_color || settingsData.primary_color,
				themeID = getThemeID(),
				fbPixelID = settingsData.fb_meta_pixel_id || settingsData.fb_pixel_id || '';

			settingsData.background_image_small = typeof (settingsData.background_image_small) === 'undefined' ? settingsData.background_image : settingsData.background_image_small;

			// debugger
			return (
				<React.Fragment>
					{state.isLoaded && state.api_url &&
						<Route render={
							(props) => {
								const location = props.location,
									pathname = location.pathname,
									// keyname = props.history.length + "_" + pathname,
									isWebview = getL('webview') || location.search.indexOf('webview') !== -1;

								if (isWebview) {
									setL('webview', true);
								}

								return (<React.Fragment>
									<MasterLoader themeID={themeID} />
									<RouterWrapper>
										<HeaderManager isWebview={isWebview}>
											<ShoppingCartManager shopEnabled={state.shopEnabled} path={pathname}>
												<Helmet
													titleTemplate={"%s - " + defaultTitle}
													defaultTitle={defaultTitle}
												>

													{settingsData.favicon &&
														<link rel="shortcut icon" href={settingsData.favicon} />
													}

													{brandPrimary &&
														<meta name="theme-color" content={"#" + brandPrimary} />
													}

													{settingsData.google_webmaster_verification &&
														<meta name="google-site-verification" content={settingsData.google_webmaster_verification} />
													}
													{/* <!-- Load google ad tag for site verification --> */}
													{/* <!-- !isWebview && googleAdClient &&
														<script data-ad-client={googleAdClient} async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
													--> */}
													{/* !isWebview && googleAdClient &&
														<script>{`
															(adsbygoogle = window.adsbygoogle || []).push({
																google_ad_client: "${googleAdClient}",
																enable_page_level_ads: false
															});
														`}</script>
													*/}
													<style>{
														// 
														`
														:root {
															--accent-color: #${brandPrimary};
															--accent-color-a70: #${rgbHex2rgbaHex(brandPrimary, 0.7)};
															--accent-color-a50: #${rgbHex2rgbaHex(brandPrimary, 0.5)};
															--accent-color-d15: #${changeColorLightness(brandPrimary, -15)};
															--accent-color-d30: #${changeColorLightness(brandPrimary, -30)};
															--accent-color-l25: #${changeColorLightness(brandPrimary, 25)};
															--vhl-header-color: #${rgbHex2rgbaHex(vhlHeaderColor, 1)};
															--botton-color: #${buttonColor}; 
															--botton-color-a70: #${rgbHex2rgbaHex(buttonColor, 0.7)};
															--botton-hover-color: #${changeColorLightness(buttonColor, -15)};
															--botton-disabled-color: #${changeColorLightness(buttonColor, -30)};
															--botton-text-color: #${buttonTextColor};

															--header-text-color: #${headerTextColor || surfaceEmphasisHigh};
															
															--background: #${backgroundBaseColor};
															--background-a80: #${rgbHex2rgbaHex(backgroundBaseColor, 0.8)};
															--background-a70: #${rgbHex2rgbaHex(backgroundBaseColor, 0.7)};
															--background-a50: #${rgbHex2rgbaHex(backgroundBaseColor, 0.5)};
															--background-a50: #${rgbHex2rgbaHex(backgroundBaseColor, 0.45)};
															--background-a20: #${rgbHex2rgbaHex(backgroundBaseColor, 0.2)};
															--background-a00: #${rgbHex2rgbaHex(backgroundBaseColor, 0)};

															--surface-primary: #${surfacePrimary};
															--surface-secondary: #${surfaceSecondary};
															--surface-secondary-a20: #${rgbHex2rgbaHex(surfaceSecondary, 0.2)};
															

															--text-primary: #${surfaceEmphasisHigh};
															--text-primary-a70: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.7)};
															--text-primary-a50: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.5)};
															--text-primary-a10: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.1)};
															--text-primary-a25: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.2)};
															--text-primary-d50: #${changeColorLightness(surfaceEmphasisHigh, -50)};
															
															--text-secondary: #${surfaceEmphasisMedium};
															--text-secondary-a50: #${rgbHex2rgbaHex(surfaceEmphasisMedium, 0.5)};
															--text-secondary-a75: #${rgbHex2rgbaHex(surfaceEmphasisMedium, 0.75)};
															--text-secondary-d15: #${changeColorLightness(surfaceEmphasisMedium, -15)};
															--text-secondary-d30: #${changeColorLightness(surfaceEmphasisMedium, -30)};
															--text-secondary-l25: #${changeColorLightness(surfaceEmphasisMedium, 25)};
															
															--header-color: #${surfacePrimary};
															--background-image: url(${settingsData.background_image});
															--background-image-sm: url(${settingsData.background_image_small});
															--facebook-login-button-color: #${socialLoginFacebookBtnBgColor};
															--facebook-login-text-color: #${socialLoginFacebookBtnTxtColot};
															--google-login-button-color: #${socialLoginGoogleBtnBgColor};
															--google-login-text-color: #${socialLoginGoogleBtnTxtColot};
															--warning-color: #${warningColor};
															--warning-color-a30: #${rgbHex2rgbaHex(warningColor, 0.3)};
													`}</style>

													<title></title>

												</Helmet>

												{/* <!-- FB Pixel Tracker  --> */}
												{fbPixelID &&
													<FBPixelTracker fbpTrackerId={fbPixelID} />
												}

												{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
												{settingsData.google_analytics &&
													<GATracker gaTrackerId={settingsData.google_analytics} />
												}
												{settingsData.ga_measurement_id &&
													<GA4Tracker gaMeasurementID={settingsData.ga_measurement_id} />
												}
												<HeaderMaster />
												{/* Player Script START*/}
												<div className="container">
													<div className='row'>
														<div id={'ram_vp2'}> </div>
													</div>
													<div className='row'>
														<VentunoPlayer
															targetID={state.videoTargetid}
															preload={state.videoPreload}
															videoId={state.videoId}
															videoKey={state.videoKey}
															ratio='16:9'
															pageTakeOver={state.videoPageTakeOver}
															limitedViewing={state.limitedViewing}
															setVideoDetails={this.setVideoDetails}
															resume={state.resume}
															forceBegin={state.forceBegin}
														/>
													</div>
												</div>
												{/* Player Script END*/}
												<Page {...props} routing_api={state.routing_api} settingsData={settingsData} themeID={themeID} setVideoDetails={this.setVideoDetails} />

												{!isWebview ? (settingsData.username === 'babadigital' || settingsData.username === 'htvfun') ? <HybridFooter termsConsentCheck={state.termsConsentCheck} settingsData={settingsData} /> :
													(themeID === 3) ? <FooterJ termsConsentCheck={state.termsConsentCheck} settingsData={settingsData} /> :
														(themeID === 2) ? <FooterE termsConsentCheck={state.termsConsentCheck} settingsData={settingsData} /> :
															<Footer termsConsentCheck={state.termsConsentCheck} settingsData={settingsData} />
													: null}
											</ShoppingCartManager>
										</HeaderManager>
									</RouterWrapper>
								</React.Fragment>);
							}
						} />
					}
				</React.Fragment>
			);
		}
	}

	handleAPIResponse(response) {
		this.setState({
			shopEnabled: !(!response.shopEnabledYN),
			termsConsentCheck: !(!response.termsConsentCheckYN),
		});
		setBaseAPIs(response, () => {
			this.setState({
				base_apis: response.base_apis,
				routing_api: response.routing_api,
				home_page: response.home_page,
				api_url: response.home_page,
				isLoaded: true,
			});
		});
		cacheRefteshTokenApi();
	}

	componentDidMount() {
		// console.log('----app mounted');
		const { appStatus } = this.state;
		if (appStatus !== "comingsoon") {
			var data = {};
			data.did = getL('did');
			if (!data.did) {
				data.did = makeId();
				setL('did', data.did);
			}
			getAPIData(process.env.REACT_APP_MANIFEST_API_PATH, { ...data }, this.handleAPIResponse)

			getSettingsAPI()
				.then((settings) => {
					this.setState({ settingsData: settings })
				})
				.catch(() => {
					console.error("Unable to get Settings Data")
				})
			return;
		} else {
			this.setState({
				...this.state,
				isLoaded: true
			})
		}
	}
}

export default App;
