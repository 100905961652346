import React from 'react';
import widgetFactoryMap from './widgetFactoryMap'

const getComponent = (type) => {
		if (type) {
			return widgetFactoryMap[type] ? widgetFactoryMap[type] : false;
		}
		return false;
	},
	widgetFactoryMapper = (type, props, optionalProps) => {
		let lProps = { ...props };

		if (type) {
			const component = getComponent(type);

			console.log("component:", component);

			if (component) {
				if (component.idPrefix) {
					lProps.id = component.idPrefix + lProps.id;
				}
				if (component.addFont) {
					lProps.addFont = optionalProps.addFont;
				}
				if (component.passType) {
					lProps.type = type;
				}
				if (component.setVideoDetails) {
					lProps.setVideoDetails = optionalProps.setVideoDetails;
				}
				if (component.onDataChange) {
					lProps.onDataChange = optionalProps.onDataChange;
				}
				if (component.temp_other) {
					lProps.temp_other = optionalProps.temp_other;
				}
				if (component.handlers) {
					lProps.handlers = optionalProps.handlers;
				}
				if (component.passFullProps) {
					//pass down all the props content to the component with id,key and others
					lProps = {
						...optionalProps.fullProps,
						...lProps
					}
				}

				return React.createElement(component.type, { ...lProps });
			}
		}
		return false;
	};



export default widgetFactoryMapper;