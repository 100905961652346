import React, { Component } from 'react';

import { ShoppingCartContext } from './ShoppingCartContext';

import { getUserDetails, getAPIData, getUrlFromBaseAPI } from '../../util/lib';

var shakeDelayTimer = 0,
	shakeStopDelayTimer = 0,
	cartAutoOpenDelayTimer = 0,
	intitPopulateTimer = 0,
	closeDelayTimer = 0;

// export the context
const RESP_SUCCESS = 'Success',
	RESP_FAILURE = 'Failure',
	/* CART_PREFIX = 'shop-cart-',
	storeCartState = (state, value) => {
		localStorage.setItem(CART_PREFIX + state, value);
	},
	getStoredCartState = (state) =>  {
		return localStorage.getItem(CART_PREFIX + state);
	},*/ 
	processShoppingCartAPIResp = (resp) => {
		const pData = resp ? resp.data || {} : {},
			widgets = pData.widgets || [],
			widget = (Array.isArray(widgets) && widgets.length) ? widgets[0] || {} : {},
			wData = widget.data || {},
			title = wData.title || '',
			cards = Array.isArray(wData.cards) ? wData.cards : [],
			cartItems = cards.filter((card = '') => {
				return card && card.type === 'ShoppingCartCard';
			});

		return {
			title,
			cards,
			cartItems
		}
	},
	clearAllTimeouts = () => {
		clearTimeout(shakeDelayTimer);
		shakeDelayTimer = 0;

		clearTimeout(shakeStopDelayTimer);
		shakeStopDelayTimer = 0;

		clearTimeout(cartAutoOpenDelayTimer);
		cartAutoOpenDelayTimer = 0;
		
		clearTimeout(intitPopulateTimer);
		intitPopulateTimer = 0;
		
		clearTimeout(closeDelayTimer);
		closeDelayTimer = 0;
	};

class ShoppingCartManager extends Component {
	constructor (props) {
		const isOpen = false;
			
		super(props);
		
		this.setAndStoreState = (state, value) => {
			const storeObj = {};

			storeObj[state] = value;
			console.log(storeObj);

			this.setState(storeObj);
			// storeCartState(state, value);
		};

		this.open = () => {
			this.setAndStoreState('isOpen', true);
		};

		this.toggleCart = () => {
			const val = !(this.state.isOpen);
			this.setAndStoreState('isOpen', val);
		};

		this.close = () => {
			this.setAndStoreState('isOpen', false);
		};

		this.delayClose = () => {
			clearTimeout(closeDelayTimer);
			closeDelayTimer = 0;
			closeDelayTimer = setTimeout(() => {
				this.close();
			}, 300);
			//
		};
		
		this.modifyCart = (apiUrl = '', product = {}, callBack = '', trueAdd = false) => {
			const pProduct = (typeof product === 'object') ? product : {},
				pCB = (typeof callBack === 'function') ? callBack : '',
				{ productKey, quantity, itemID } = pProduct,
				pQuantity = parseInt(quantity) || 1,
				userDetails = getUserDetails() || {};
			
			console.log(productKey, quantity, itemID);
			console.log(productKey, trueAdd);
			
			if (apiUrl && productKey) {
				getAPIData(apiUrl, {
					user_id: userDetails.id || 0,
					item_id: itemID || '',
					product_key: String(productKey),
					qty: (pQuantity < 0) ? 0 : pQuantity
				}, (resp) => {this.handleCartAPIResp(resp, trueAdd, (callBackResp) => {
					if (pCB) {
						pCB(callBackResp);
					}
				})});
				// console.log(url, productKey, quantity);
			} else {
				if (pCB) {
					pCB(RESP_FAILURE);
				}
			}
		};

		this.handleCartAPIResp = (resp = '', trueAdd = false, callBack = '') => {
			const callBackResp = (resp) ? (resp.error) ? RESP_FAILURE : RESP_SUCCESS : RESP_FAILURE;

			if (callBackResp === RESP_SUCCESS) {
				const cartContent = processShoppingCartAPIResp(resp),
					{ cartItems } = cartContent;

				console.log(cartContent);

				this.setState({
					itemCount: cartItems.length || 0,
					cartContent
				});

				clearAllTimeouts();
				if (trueAdd) {
					shakeDelayTimer = setTimeout(() => {
						this.setState({
							animateIcon: true
						});
					}, 500);

					cartAutoOpenDelayTimer = setTimeout(() => {
						this.open();
					}, 1000);

					shakeStopDelayTimer = setTimeout(() => {
						this.setState({
							animateIcon: false
						});
					}, 1400);
				}
			}

			if (callBack) {
				callBack(callBackResp);
			}
		};

		this.refreshCart = () => {
			const cartAPI = getUrlFromBaseAPI('cart_page') || '';

			if (cartAPI) {
				intitPopulateTimer = setTimeout(() => {
					const userDetails = getUserDetails() || {};

					getAPIData(cartAPI, {	
						user_id: userDetails.id
					}, (resp = {}) => {
						console.log(resp);
						this.handleCartAPIResp(resp);
					});
				}, 200);
			}
		}
	
		// State also contains the updater function so it will
		// be passed down into the context provider
		this.state = {
			shopEnabled: props.shopEnabled || false,
			animateIcon: false,
			itemCount: 0,
			isOpen,
			cartContent: '',
			open: this.open,
			close: this.close,
			toggleCart: this.toggleCart,
			modifyCart:  this.modifyCart,
			refreshCart:  this.refreshCart
		};

		this.refreshCart();
	}

	componentWillUnmount () {
		clearAllTimeouts();
	}

	componentDidUpdate (prevProps) {
		if (prevProps.path !== this.props.path) {
			this.delayClose();
		}
	}

	render() {
		return (
			<ShoppingCartContext.Provider value={this.state}>
				{this.props.children}
			</ShoppingCartContext.Provider>
		);
	}
}

export default ShoppingCartManager;