import React, { Component } from 'react';
import Popup from '../popup/Popup';
import { withRouter } from 'react-router';

const POP = 'POP',
	PUSH = 'PUSH',
	POPUP_START_COUNT = 'POPUP_START_COUNT';

class PopupMaster extends Component {
	state = {
		popupStateActive: false,
		hash: ''
	}

	componentDidMount() {
		const props = this.props,
			location = props.location || {},
			hash = location.hash || '',
			popupStateActive = !(!hash);

		this.setState({ popupStateActive }, () => {
			if (!this.state.popupStateActive) {
				window.sessionStorage.setItem(POPUP_START_COUNT, 0);
			}
			// console.log('FF@!', 'AT GET', sessionStorage.getItem(POPUP_START_COUNT));
		});
	}

	componentDidUpdate() {
		const props = this.props,
			location = props.location || {},
			hash = location.hash || '',
			popupStateActive = !(!hash),
			history = props.history || {};

		if (hash !== this.state.hash) {
			this.setState({ hash }, () => {
				if (popupStateActive !== this.state.popupStateActive) {
					this.setState({ popupStateActive }, () => {
						if (this.state.popupStateActive && history.action === PUSH) {
							//console.log('FFFFF', 'c1', history, location);
							const backCount = 1;

							window.sessionStorage.setItem(POPUP_START_COUNT, backCount);
							history.replace(location.pathname + location.hash, { backCount });
							//console.log('FFFFF', 'c1', history, location);
						}
					});
				} else if (popupStateActive && history.action === PUSH) {
					//console.log('FFFFF', 'c2', history, location);
					const backCountRaw = window.sessionStorage.getItem(POPUP_START_COUNT),
						backCount = (parseInt(backCountRaw) || 1) + 1;

					//bugfix login popup not opening after successfull password reset
					window.sessionStorage.setItem(POPUP_START_COUNT, backCount);

					history.replace(location.pathname + location.hash, { backCount });
					//console.log('FFFFF', 'c2', history, location);
				}
			});
		}

	}

	onCloseClick = () => {
		const props = this.props,
			location = props.location || {},
			history = props.history || {},
			historyLength = history.length || 0,
			locationState = location.state || {},
			backCount = locationState.backCount;

		console.log('FFFFF', 'DD', locationState, backCount);

		if (historyLength <= 1 || !backCount) {
			history.replace(location.pathname || '/');
		} else {
			history.go(backCount * -1);
		}
		//bugfix login popup not opening after successfull password reset
		window.sessionStorage.setItem(POPUP_START_COUNT, 0);
	}

	render() {
		const { popups, ...rest } = this.props || '';

		return (<>
			{popups && popups.map((popup, index) => {
				const popupData = popup.data || {},
					popupMeta = popup.meta ||{},
					popupId = popupData.uri || '',
					styleMeta = popupMeta.style || '';

				return <Popup data={popup} id={popupId} key={'popup-' + index} {...rest} onCloseClick={this.onCloseClick} additionalClass={styleMeta} />
			})}
		</>);
	}
};

export default withRouter(PopupMaster);
