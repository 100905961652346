import React, { Component } from 'react';
import './../scss/Page.scss';

import ClassWrapper from './factory/ClassWrapper';
import ShoppingCart from './ShoppingCart';

import { ShoppingCartContext } from './ShoppingCartContext';
import { HeaderContext } from './header/HeaderContext';

import { getAPIData, getL, clearLC, clearL, getUrlFromBaseAPI } from '../../util/lib';
import { gaPageView, fbPixelPageView } from '../../util/trackerUtil';
import WidgetFactory from './factory/WidgetFactory';
import { RouterContext } from './router/RouterContext';
import { ga4PageView } from './trackers/GA4Tracker';
import { parsePlayerBeacons, processBeacons } from '../../util/beaconUtils';

const ORDER_ID = 'order_id',
	SHIPPING_ID = 'shipping_id',
	COUNTRY_ID = 'country_id',
	STATE_ID = 'state_id',
	SIMPLY_SOUTH_URL = 'https://www.simplysouth.tv',
	ADTNL_POST_DATA_KEYS = [ORDER_ID, SHIPPING_ID, COUNTRY_ID, STATE_ID];

class Page extends Component {
	static contextType = RouterContext;
	constructor(props = {}) {
		const isWebview = getL('webview');

		super(props);

		this.state = {
			widgets: null,
			nextAPI: '',
			hideHeader: (isWebview) ? true : false,
			canLoadHeader: false,
			dataLoaded: false,
			pageCount: 0,
			meta: {},
		}

		this.onDataChange = this.onDataChange.bind(this);
		this.handleAPIResponse = this.handleAPIResponse.bind(this);
		this.handleRoutingResponse = this.handleRoutingResponse.bind(this);
	}

	componentDidUpdate(prevProps) {
		//this will prevent rerender / load the page when same route is pushed to history
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.loadPage();
		}
	}

	componentDidMount() {
		this.loadPage();
	}

	loadPage() {
		const props = this.props || {},
			routing_api = props.routing_api || '',
			location = props.location || {},
			wLocation = window.location || {},
			pathname = location.pathname,
			url = wLocation.href || SIMPLY_SOUTH_URL + pathname;

		if (pathname.indexOf('/payment') === -1 && pathname.indexOf('/plans') === -1 && pathname.indexOf('/login') === -1 && pathname.indexOf('/register') === -1) {
			clearL('payment_plan_id');
			clearL('payment_plan_video_id');
			clearL('payment_plan_id_2'); // Arun - payment_plan_id_2 is an alternative to store plan id for Master Class
			clearL('payment_plan_course_id');
			clearL('start_date');
			clearL('lastVideoLocation');
		}
		if(pathname.indexOf('/watch') === -1){
			clearL('expert_id');
			clearL('master_class_id');
		}
		if(pathname.indexOf('/category') === -1){
			clearL('class');
		}
		if(pathname.indexOf('/category') === -1){
			clearL('category_guid');
		}
		if (pathname.indexOf('/login') === -1) {
			clearL('destination_page_path');
		}

		ga4PageView(pathname);
		

		this.setState({ nextAPI: '', dataLoaded: false, pageCount: (this.state.pageCount + 1) }, () => {
			const context = this.context || {},
				embededRoute = context.route || '',
				setRoute = context.setRoute;

			if (embededRoute) {
				if (setRoute) {
					setRoute(); // remove embededRoute
				}
				this.handleRoutingResponse(embededRoute);
			} else {
				getAPIData(routing_api, {
					url
				}, this.handleRoutingResponse);
			}
		});

		
	}

	handleRoutingResponse(response) {
		const props = this.props || {},
			location = props.location || {},
			pathname = location.pathname,
			apiURL = response.api_url + location.search,
			wLocation = window.location || {},
			pageURL = wLocation.href || SIMPLY_SOUTH_URL + pathname,
			postData = ADTNL_POST_DATA_KEYS.reduce((pd = {}, key) => {
				const val = getL(key) || '',
					obj = {};

				if (val) {
					obj[key] = val;
					return { ...pd, ...obj };
				}

				return pd;
			}, {
				url: pageURL
			}),
			plan_id = getL('payment_plan_id_2') || getL('payment_plan_id') || '',
			course_id = getL('payment_plan_course_id') || '',
			video_id = getL('payment_plan_video_id') || '',
			show_id = getL('show_id') || '',
			isUpgradableUser = getL('is_upgradable_user') || false,
			category_guid = getL('category_guid') || "",
			classType = getL('class') || "",
			expert_id = getL('expert_id') || "",
			master_class_id = getL('master_class_id') || "",	
			beacons = getUrlFromBaseAPI('beacons') || '';

		if (beacons) {
			processBeacons(parsePlayerBeacons(beacons));
		}

		clearL('is_upgradable_user:',classType);

		if (props.setVideoDetails) {
			props.setVideoDetails({});
		}

		getAPIData(apiURL, {
			...postData,
			plan_id,
			video_id,
			course_id,
			category_guid,
			expert_id,
			master_class_id,
			class: classType,
			content_show_id:show_id,
			deferred_load_supported: true,
			is_upgradable_user: isUpgradableUser
		}, this.handleAPIResponse);
	}

	handleAPIResponse(response = {}) {
		const props = this.props,
			location = props.location,
			data = response.data || {},
			meta = response.meta || {},
			pathname = location.pathname || '/';

		console.log('GGGGGGGGGGGGGGGG', location);

		gaPageView(pathname);
		fbPixelPageView(pathname);

		ADTNL_POST_DATA_KEYS.forEach((key = '') => {
			clearLC(key);
		});

		window.scrollTo(0, 0); //the page should scroll to top before rendering page 

		this.setState({
			widgets: []
		}, () => {
			this.setState({
				widgets: data.widgets || [],
				meta,
				nextAPI: (meta.isDeferredLoadedYN && data.next_url) ? data.next_url : '',
				canLoadHeader: true,
				dataLoaded: true,
			});
		});
	}

	onDataChange(data, nextAPI = '') {
		// debugger;
		this.setState({
			widgets: data
		}, () => {
			if (nextAPI && nextAPI !== this.state.nextAPI) {
				this.setState({
					nextAPI
				});
			}
		});
	}

	render() {
		const state = this.state,
			props = this.props,
			themeClass = this.props.className || '',
			settingsData = props.settingsData || {},
			addFont = settingsData.accent_font || '',
			location = props.location || {},
			pathname = location.pathname;

		return (
			<React.Fragment>
				{!state.hideHeader && <HeaderContext.Consumer>
					{({ setPageMeta, setPageLoading, setPath, load, hide }) => {
						const state = this.state;

						if (state.dataLoaded) {
							if (this.state.canLoadHeader) {
								this.setState({ canLoadHeader: false }, () => {
									hide();
									setPageMeta(state.meta);
									load();
									setPageLoading(false);
									setPath(String(pathname).replace('/', ''));
								});
							}
						} else {
							setPageLoading(true);
						}
					}}
				</HeaderContext.Consumer>}
				<ShoppingCartContext.Consumer>
					{({ shopEnabled }) => {
						return shopEnabled ? (<ShoppingCart />) : '';
					}}
				</ShoppingCartContext.Consumer>

				<div className={'site-body ' + themeClass}>
					<WidgetFactory pageCount={state.pageCount} location={location} nextAPI={state.nextAPI} widgets={state.widgets} onDataChange={this.onDataChange} addFont={addFont} setVideoDetails={props.setVideoDetails} />
					
				</div>
			</React.Fragment>
		);
	}
}

export default ClassWrapper(Page);


