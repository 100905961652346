import React from 'react';
import CoverScreen from '../ui/CoverScreen';

import { Link } from "react-router-dom";

import '../../scss/videoPlayer/LimitedViewingPopup.scss';

const LVButton = (props = {}) => {
		const data = props.data || {},
			action = data.action || '',
			urlObj = data.url || {},
			path = urlObj.path || '',
			isLink = action !== 'continue',
			classStr = `btn btn-lg vt-btn ${props.primaryButton ? 'vt-btn-primary' : 'vt-btn-secondary'}`;

		return (<span>
			{(isLink) ? 
			<Link to={path} className={classStr} >{data.label}</Link> : 
			<button onClick={props.onContinue} className={classStr} >{data.label}</button>}
		</span>);
	},

	LimitedViewingPopup = (props = {}) => {
		const data = props.data || {},
			message = data.message || '',
			onContinue = (evt) => {
				console.log(evt);
				if (props.onContinue) {
					props.onContinue();
				}
			},
			actionButtons = data.action_buttons || {},
			primary = actionButtons.primary || {},
			secondary = actionButtons.secondary || {};

		return (<div className="limited-viewing-popup">
			<CoverScreen className="screen" show={true} />
			<div className="message-box">
				<div className="text-wrp">
					<h2 className="text-center">{message}</h2>
					<div className="btns text-center">
						{primary && <LVButton data={primary} primaryButton={true} onContinue={onContinue}/>}
						{secondary && <LVButton data={secondary} primaryButton={false} onContinue={onContinue}/>}
					</div> 
				</div> 
			</div>
		</div>);
	}

export default LimitedViewingPopup;