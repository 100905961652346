import React, { Component } from 'react';
import { getThemeID } from '../../../util/lib';

export const VENUS = 'venus';
export const JUPITER = 'jupiter';
export const EARTH = 'earth';
export const MARS = 'mars';

const ThemeWrapper = (Comp) => {
	class ThemeWrapperComp extends Component {
		render() {
			const themeID = getThemeID(),
				theme = (themeID === 3) ? JUPITER : (themeID === 2) ? EARTH : (themeID === 4) ? MARS : VENUS;

			return (themeID ? <Comp {...{ themeID, theme, ...this.props }} /> : null);
		}
	};

	return ThemeWrapperComp;
}

export default ThemeWrapper;