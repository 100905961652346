import React from 'react';
import FooterRequest from '../../../js/request/FooterRequest';
import PrivacyCheck from '../../PrivacyCheck';
import FooterLayoutFactory from './layout/FooterLayoutFactory';

const HybridFooter = (props) => {

	// LOADING FROM PROPS DATA
	const data = props.footerData || {},
		widgets = data.widgets || [],
		layout = props.footerLayout || 1,
		privacyCheck = widgets.filter(widget => widget.type === "PrivacyCheck");

	return (
		<>
			<FooterLayoutFactory data={widgets} layout={layout} />
			{/*privacy footer */}
			{!props.termsConsentCheck && privacyCheck[0] && <PrivacyCheck widget={privacyCheck[0]} />}
		</>);
}

export default FooterRequest(HybridFooter);