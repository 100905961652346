import { createUserSession, isSecureLogin, isToeknAvailable, isTokenAlive, LogoutUser, refreshAuthToken } from "./authUtils";
import { post } from "./fetchRequest";
import { parseAPIResponse, getL, showNotification } from "./lib";

export const getApiDataV2 = (url, data, callback) => {
    try {
        if (isSecureLogin() && isToeknAvailable() && !isTokenAlive()) {
            console.log("token expired");
            refreshAuthToken().then((successData) => {
                console.log("token refreshed:resolve");
                if (successData.status) {
                    createUserSession('localstore', {
                        token: successData.token,
                        refreshToken: successData.refresh_token
                    });
                    console.log("calling api");
                    apiCall(url, data, callback);
                }
            }, (errorData) => {
                console.log("token refreshed:reject:", errorData);
                if (!errorData.status) {
                    LogoutUser();
                    console.log("not able to get refresh token");
                    window.location.href = '/login';
                }
            });
        } else {
            apiCall(url, data, callback);
        }

    } catch (exception) {
        console.log('getApiDatav2 Failed:', exception);
    }
    console.log("getapiv2 last line");
}

export const apiCall = (url, data, callback) => {
    const vt_auth_token = getL('vt-auth-token') || {},
        { token = '' } = vt_auth_token;

    if (token) {
        data.token = token;
    }
    post(url, data).then((response = {}) => {
        const parsedResponse = parseAPIResponse(response);
        console.log("parsedResponse111:", parsedResponse);
        checkForSessionErrors(parsedResponse);
        callback(parsedResponse);
    }).catch((exception = '') => {
        console.log('getApiData Failed:', exception);
    });
    console.log("getapiv2 api call");
}

export const checkForSessionErrors = (response = {}) => {
    const { type = '', data = {} } = response;
    if (type === 'Error') {
        if (data.message === 'Session Expired') {
            LogoutUser();
            window.location.href = '/login';
        }
    }
}