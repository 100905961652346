import React, { Component } from 'react';


import { NavLink, Link } from 'react-router-dom';

import { ShoppingCartContext } from '../ShoppingCartContext';

import '../../scss/earth/HeaderE.scss';
import { getRelativeUrl, clearUserDetails } from '../../../util/lib';
import { ga4Event } from '../trackers/GA4Tracker';
import { LogoutUser } from '../../../util/authUtils';


const DD_MENU_ID = 'dropdown-menu',
	NAVBAR_DROPDOWN_MENU_LINK = 'NavbarDropdownMenuLink',
	NAVBAR_SUPPORTED_CONTENT = 'navbarSupportedContent',
	getURL4Item = (item = {}) => {
	const data = item.data || {},
		link = data.link || {}
	
	return link.url || '';
};

class HeaderE extends Component {
  constructor(props) {
		const videoDetails = props.videoDetails || {};

		super(props);

		this.state = {
			error: null,
			isLoaded: false,
			header: null,

			settingsData: null,

			gotPageMeta: null,
			canShowHeaderLinksYN: null,
			canShowSearchBoxYN: null,
			showExploreButtonYN: null,
			showFreeTrailButtonYN: null,
			canShowUserInfoYN: null,
			canShowShoppingCartYN: false,
		};

		this.setPlayerDetails = (data) => {
			this.setState({
				...data,
				videoPreload: false
			});
		}
	}
	
	componentDidUpdate (prevProps) {
		if (this.props.hideCount !== prevProps.hideCount) {
			const document = window.document || '',
				navBar = (document && document.querySelector) ? document.querySelector(`#${NAVBAR_SUPPORTED_CONTENT}`) || '' : '';

			if (navBar) {
				navBar.className = String(navBar.className).replace('show', '');
			}
		}
	}

  render() {
	const state = this.state,
		props = this.props;

	if (!props.header) {
		return (null);
	} else if (!props.settingsData) {
		return (null);
	} else {
		const settingsData = props.settingsData,
			logo = settingsData.logo,
			addFont = settingsData.accent_font || '',
			data = props.header && props.header.data,
			links = data.links || false,
			additionalLinks = data.additional_links || false,
			
			siteLogo = props.siteLogo || {},
			siteLogoData = siteLogo.data || {},
			siteLogoLinkObj = siteLogoData.link || {},
				
			userInfo = data.user_info || false,
			exploreButton = data.explore_button || false,
			premiumButton = data.premium_button || false,
			startTrialButton = data.start_trial_button || data.start_trail_button || false;
			
		return (<React.Fragment>
				<nav className="navbar navbar-expand-lg navbar-dark site-header sticky-top earth">

					{siteLogoLinkObj && <Link to={getRelativeUrl(siteLogoLinkObj.url)} className="navbar-brand">
						<img src={logo} className="app-logo" alt="logo"/>
					</Link>}

					{state.canShowHeaderLinksYN && <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#${NAVBAR_SUPPORTED_CONTENT}`} aria-controls={NAVBAR_SUPPORTED_CONTENT} aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon vt vt-menu"></span>
					</button>}

					<div className="collapse navbar-collapse" id={NAVBAR_SUPPORTED_CONTENT}>
						<ul className="navbar-nav all-links">
							{state.canShowHeaderLinksYN && links && links.map((item = {}, index) => {
								/*const data = item.data || {},
									linkObj = data.link || {},
									linkURL = getRelativeUrl(linkObj.url || ''),
									type = item.type,
									isActive = (type === 'Link' && props.page === '' && linkURL === '/home');

								if (type === 'Link') {
									return (
										<li key={index} className="nav-item">
											<NavLink key={'nv' + index} to={linkURL} className={'nav-link' + (addFont ? ' ' + addFont : '') + (isActive ? ' active' : '')} activeClassName="active">
												{item.data.title}
											</NavLink>
										</li>
									);
								}*/
								const type = item.type;
								
								if (type === 'Link' || type === 'DropDown') {
									const iData = item.data || {},
										linkkObj = iData.link || {},
										linkURL = getRelativeUrl(linkkObj.url || ''),
										isActive = (type === 'Link' && props.page === '' && linkURL === '/home'),
										title = iData.title || '',
										clsName = 'nav-link' + (addFont ? ' ' + addFont : '') + (isActive ? ' active' : '') + ((type === 'DropDown') ? ' ' : ''),
										subMenu = iData.sub_menu || [];
									
									return (
										<li key={'lnk-' + index} className="nav-item">	
											{type === 'Link' ? <NavLink to={linkURL} className={clsName + ' true-link'} activeClassName="active">
												{title}
											</NavLink> : <><a className={clsName + ' true-link'} href='/' id={NAVBAR_DROPDOWN_MENU_LINK + '-' + index} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span className="dropdown-toggle">{title}</span>
											</a>
											<div className={DD_MENU_ID + ' sub-link'} aria-labelledby={NAVBAR_DROPDOWN_MENU_LINK + '-' + index}>
												{subMenu.map((subLink, subIndex) => {
													const slData = subLink.data || {},
														sLinkkObj = slData.link || {},
														sLinkURL = getRelativeUrl(sLinkkObj.url || '');

													return (
														<Link key={DD_MENU_ID + subIndex} to={sLinkURL} className="dropdown-item">{slData.title}</Link>
													);
												})}
											</div></>}
										</li>
									);
								}
								return false;
							})}
						</ul>

						<ul className="navbar-nav all-links add-links">
							{state.canShowHeaderLinksYN && additionalLinks && [...additionalLinks].map((item = {}, index) => {
								const meta =  item.meta || {},
									linkType = meta.isButtonYN ? 'btn' : meta.showIconYN ? 'icon' : '',
									addClass = meta.isButtonYN ? ' btn btn-sm vt-btn vt-btn-link' : meta.showIconYN ? ' vt-icon' : '',
									data = item.data || {},
									linkObj = data.link || {},
									linkURL = getRelativeUrl(linkObj.url || '');

								if (item.type === 'Link') {
									const title = data.title || '';
									if (title.indexOf('Log Out') !== -1) {
										return (
											<li key={index} className="nav-item">
												<span className={'nav-link' + addClass + (addFont ? ' ' + addFont : '')} onClick={() => {
													ga4Event('logout', {method: "Ventuno"});
													clearUserDetails();
													LogoutUser();
													window.location.reload(true);
												}}>{title}</span>
											</li>
										)	
									} else {
										return (
											<li key={index} className="nav-item">
												<NavLink key={'anv' + index} to={linkURL} className={'nav-link' + addClass + (addFont ? ' ' + addFont : '')}>
													{linkType === 'icon' ? <i className="vt vt-search" aria-hidden="true"></i> : title}
												</NavLink>
											</li>
										);
									}
								}
								return false;
							})}
						</ul>

						{ state.showExploreButtonYN && exploreButton && ((item) => {
							var link_url = getRelativeUrl(getURL4Item(item));
							if (item.type === 'Button') {
								// d-none d-lg-block
								return (
									<span className="navbar-text explore-button">
										<Link to={link_url} className="btn btn-lg vt-btn vt-btn-link nav-link text-uppercase">
											{item.data.text} <i className="vt vt-browse " aria-hidden="true"></i>
										</Link>
									</span>
								);
							}
						})(exploreButton) }

						{ state.showFreeTrailButtonYN && startTrialButton && ((item) => {
							var link_url = getRelativeUrl(getURL4Item(item));
							if (item.type === 'Button') {
								// d-none d-lg-block
								return (
									<span className="navbar-text start-trial-button">
										<Link to={link_url} className="btn btn-lg vt-btn vt-btn-primary text-uppercase">
											{item.data.text}
										</Link>
									</span>
								);
							}
						})(startTrialButton)}
						{ premiumButton && ((item) => {
							var link_url = getRelativeUrl(getURL4Item(item));
							if (item.type === 'Button') {
								return (
									<span className="navbar-text start-trial-button">
										<Link to={link_url} className="btn btn-lg vt-btn vt-btn-primary text-uppercase">
											{item.data.text}
										</Link>
									</span>
								);
							}
						})(premiumButton)}
						
						{ state.canShowUserInfoYN && userInfo && ((item) => {
							var link_url = getRelativeUrl(getURL4Item(item));

							if (item.type === 'Link') {
								return (
									<span className="navbar-text login-button">
										<Link to={link_url} className="btn btn-lg vt-btn vt-btn-primary text-uppercase">
											{item.data.title}
										</Link>
									</span>
								);
							}
							if (item.type === 'ProfilePicture') {
								const DD_MENU_ID = 'dropdown-menu';

								return (
									<ul className="navbar-nav user-profile-button">
										<li  className="nav-item dropdown">
											<a className="nav-link dropdown-toggle user-icon" href="/" id={NAVBAR_DROPDOWN_MENU_LINK} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span className='text-primary'>{item.data.name}</span>
												<i className="vt vt-usersolid text-accent" aria-hidden="true"></i>
											</a>
											<div className={DD_MENU_ID} aria-labelledby={NAVBAR_DROPDOWN_MENU_LINK}>
												<Link to={link_url} className="dropdown-item">
													Profile
												</Link>
												
												{item.data.sub_menu.map((item, index) => {
													var link_url = getRelativeUrl(item.data.link.url);
													if (item.type === 'Link') {
														if (link_url.indexOf('logout') !== -1) {
															// debugger;
															return (
																<span key={DD_MENU_ID + index} className="dropdown-item" onClick={ () => {
																	ga4Event('logout', {method: "Ventuno"});
																	clearUserDetails();
																	window.location.reload(true);
																} }>{item.data.title}</span>
															);
														} else if (link_url.indexOf('order') !== -1) {
															const orderLink = getRelativeUrl(item.data.link.url);

															return (
																<Link key={DD_MENU_ID + index} to={orderLink} className="dropdown-item">{item.data.title}</Link>
															)
														}
													}
													return false;
												})}
				
											</div>
										</li>
									</ul>
								);
							}
						})(userInfo) }

						<ShoppingCartContext.Consumer>
							{({shopEnabled, itemCount, toggleCart, animateIcon}) => {

								return shopEnabled && state.canShowShoppingCartYN ? (<span className="navbar-icon">
									<div onClick = {toggleCart} className={'vt-btn-like' + (animateIcon ? ' vn-shake' : '')}>
										<div className="shop-icon">
											<i className="fas fa-shopping-cart"></i>
										</div>
										{(itemCount && itemCount > 0) ? <div className="shop-item-count">{itemCount}</div> : ''}
									</div>
								</span>) : '';
							}}
						</ShoppingCartContext.Consumer>

					</div>
				</nav>
			</React.Fragment>);
		}
  	}

  	static getDerivedStateFromProps(props, state) {

		if (Object.keys(props.pageMeta).length > 0) {
			const header = props.header || '';

			const settingsData = props.settingsData || '';

			const showExploreButtonYN = (typeof(props.pageMeta.showExploreButtonYN) !== 'undefined') 
			? props.pageMeta.showExploreButtonYN
			: false;

			const canShowSearchBoxYN = (typeof(props.pageMeta.canShowSearchBoxYN) !== 'undefined') 
			? props.pageMeta.canShowSearchBoxYN
			: true;

			const canShowHeaderLinksYN = (typeof(props.pageMeta.canShowHeaderLinksYN) !== 'undefined') 
			? props.pageMeta.canShowHeaderLinksYN
			: true;

			const showFreeTrailButtonYN = (typeof(props.pageMeta.showFreeTrailButtonYN) !== 'undefined') 
			? props.pageMeta.showFreeTrailButtonYN
			: false;

			const canShowUserInfoYN = (typeof(props.pageMeta.canShowUserInfoYN) !== 'undefined') 
			? props.pageMeta.canShowUserInfoYN
			: true;

			return {
				header,
				settingsData,
				gotPageMeta: true,
				showExploreButtonYN,
				canShowSearchBoxYN,
				canShowHeaderLinksYN,
				showFreeTrailButtonYN,
				canShowUserInfoYN,
			}
		} else {
			return null;
		}
  	}
}

export default HeaderE;
