import { Component } from 'react';
import ReactGA from 'react-ga';

const initReactGA = (gaTrackerId = '') => {
	// const testGAID = 'UA-153102942-1'; //TEUX DEUX;
	ReactGA.initialize(gaTrackerId , { debug: false }); //TEUX DEUX - chng to false for production 
	if (window) {
		window.ReactGA = ReactGA;
	}
}

class GATracker extends Component {
	constructor(props) {
		const gaTrackerId = props.gaTrackerId || '';
		super(props);
		initReactGA(gaTrackerId);
	}

	componentDidUpdate(prevProps) {
		const props = this.props,
			gaTrackerId = props.gaTrackerId || '';

		if (this.props.gaTrackerId !== prevProps.gaTrackerId) {
            initReactGA(gaTrackerId);
		}
	}
	
	render () {

		return (null);
	}
}

export default GATracker;