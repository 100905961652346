import React from 'react';

export const HeaderContext= React.createContext({
	isWebview: false,
	pageLoading: false,
	siteLogo: '',
	widget: {},
	pageMeta: {},
	videoDetails: {},
	settingsData: '',
	page: '',
	hideCount: 0,
	setPageMeta: () => {},
	setPageLoading: () => {},
	setPath: () => {},
	unLoad: () => {},
	load: () => {},
	hide: () => {}
});