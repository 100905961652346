import React, { Component } from 'react';

import './../scss/SearchForm.scss';

//import { parseAPIResponse, getUrlFromBaseAPI, showNotification } from './../../util/lib';
import { getAPIData, showNotification } from './../../util/lib';
import { pixelTrack, gaEvent } from '../../util/trackerUtil';
import PropTypes from 'prop-types';
import { ga4Event } from './trackers/GA4Tracker';

const defaultSearchLabel = 'Search',
	trackSearch = () => {
		const trackerEvent = defaultSearchLabel;

		pixelTrack(trackerEvent);
		gaEvent(trackerEvent);
	};

class SearchForm extends Component {
	constructor(props) {
		const wData = props.data || {},
			data = wData.data || {},
			qry = data.qry || '',
			labels = data.labels || {},
			searchLabel = labels.search_label_small || defaultSearchLabel;

		console.log(data);

		super(props);
		

		if (qry) {
			trackSearch();
		}

		this.state = {
			prevvalue: qry,
			value: qry,
			data: null,
			searchLabelSmall: searchLabel,
			searchLabelBig: labels.search_label_large || searchLabel,
			searchLabel,
			searchId: 'vtn-src-bx-' + (Math.floor(Math.random()*90000) + 10000),
			searchSuggestions: [],
		};
	
		this.searchTextChange = this.searchTextChange.bind(this);
		this.searchFormSubmit = this.searchFormSubmit.bind(this);
		this.suggestionClick = this.suggestionClick.bind(this);
		this.checkSize = this.checkSize.bind(this);
	}
	searchTextChange (event = {}) {
		const target = event.target || {},
			value = target.value || '';
		

		this.setState({value}, () => {
			const searchSuggestions = this.state.searchSuggestions || [],
				searchValue = this.state.value;

			if (searchSuggestions.indexOf(searchValue) >= 0) {
				ga4Event('search', {search_term: searchValue});
				window.location.href = '/search?qry=' + encodeURIComponent(searchValue);
			} else {
				this.searchSuggestionsRequest();
			}
		});

	}

	searchSuggestionsRequest () {
		const wData = this.props.data || {},
			data = wData.data || {},
			searchSuggestionsURLObj = data.searchSuggestionsURL || {},
			searchSuggestionsURL = searchSuggestionsURLObj.url || '',
			qry = this.state.value || '';

			if (searchSuggestionsURL && qry) {
				getAPIData(searchSuggestionsURL, {qry}, (response = {}) => {
					const responseData = response.data || {},	
						widgetsRaw = responseData.widgets || [],
						widgets = Array.isArray(widgetsRaw) ? widgetsRaw : [],
						widget = widgets.length ? widgets[0] || {} : {},
						wData = widget.data || {},
						data = wData.data || [],
						searchSuggestions = Array.isArray(data) ? data : [];

					console.log(widget);
					
					this.setState({searchSuggestions});
				});
			} else {
				this.setState({searchSuggestions: []});
			}
	}
	
	searchFormSubmit(event) {
		const state = this.state,
			searchValue = state.value,
			prevValue = state.prevvalue;

		this.setState({
			prevvalue: state.value
		}, () => {
			if (searchValue === "") {
				showNotification({text: "Search is empty"}); 
			} else if (searchValue === prevValue) { // compare previous state value with current state value
				event.preventDefault(); 
			} else {
				ga4Event('search', {search_term: searchValue});
				window.location.href = '/search?qry=' + encodeURIComponent(searchValue);
			}
		});

		event.preventDefault();
	}
	  
	suggestionClick (event) {
		this.setState({
			value: event.target.getAttribute('value')
		}, () => {
			window.$('#sf').trigger('submit')
		});
	}

	datalistOnSelect (event) {
		console.log(event)
	}

	checkSize () {
		const state = this.state,
			searchId = state.searchId,
			searchBox = document.querySelector('#' + searchId) || {},
			searchWidth = searchBox.offsetWidth || 0,
			searchLabel = (searchWidth <= 0 || searchWidth >= 255) ? state.searchLabelBig : state.searchLabelSmall

		console.log(searchWidth);

		this.setState({ searchLabel });
	}

	componentWillMount () {
		window.removeEventListener('resize', this.checkSize);
	}

	componentDidMount () {
		this.checkSize();
		window.addEventListener('resize', this.checkSize);
	}

	render () {
		const props = this.props,
			state = this.state,
			propsData = props.data || {}, 
			data = state.data || propsData.data,
			id = props.id,
			title = data.title || null,
			containerYN = props.inline ? '' : ' container',
			inPage = props.inline ? '' : ' in-page',
			actionURL = data.actionURL || {},
			action = actionURL.path,
			searchSuggestions = state.searchSuggestions;

		// TUUX DEUX ~ clean up
		const most_recent = this.props.data.data.suggestions.most_recent;
		const most_watched = this.props.data.data.suggestions.most_watched;
		//localization
		var title_mostRecent = 'Most Recent';
		var title_mostWatched = 'Most Watched';

		return (
			<div id={id} className={'v-widget search-form' + containerYN }>

				{title && 
					<div className='widget-title'>
						<h2>{title}</h2>
					</div>
				}
				<form id={"sf"} action={action} className='form-inline' onSubmit={this.searchFormSubmit}>
					<div className={'input-group' + inPage }>
						<input 
							className="form-control search-box"
							id={state.searchId}
							placeholder={state.searchLabel}
							type="search"
							aria-label="Search"
							name='qry'
							value={state.value}
							onChange={this.searchTextChange}
							autoComplete={"off"}
							list="search-list" 
						/>
						{<datalist className="search-list" id="search-list">
							{searchSuggestions.map((suggestion = '', index = 0) => {
								return (<option key={'op-' + index} value={String(suggestion)} />);
							})}
						</datalist>}
						<button className="btn search-button" type="submit">
							<i className="vt vt-search" aria-hidden="true"></i>
						</button>
						<br/><br/>
						{/* <div className="input-group-append">
							<button className="btn vt-btn vt-btn-primary" type="submit">
								<i className="vt vt-search" aria-hidden="true"></i>
							</button>
						</div> */}
					</div>
				</form>
				{!this.state.value && 
					<div className={"container-fluid"}>
						<div className={"suggestions row"}  >
							{most_recent && Object.keys(most_recent).length > 0 &&
								<div className={"col-6"}>
									<div className="widget-title">
										<h2> {title_mostRecent}</h2>
									</div>
									{most_recent.map((item,index) =>
										<li value={item} key={index} onClick = {this.suggestionClick}>
											{item}
										</li>
									)}
								</div>
							}

							{most_watched && Object.keys(most_watched).length > 0 &&
								<div className="col-6">
									<div className="widget-title">
										<h2> {title_mostWatched}</h2>
									</div>
									{most_watched.map((item,index) =>
										<li value={item} key={index} onClick = {this.suggestionClick}>
											{item}
										</li>
									)}
								</div>
							}
						</div>
					</div>
				}
			</div>
		);
	}
}


SearchForm.propTypes = {
	id: PropTypes.string,
	data: PropTypes.shape({
		data: PropTypes.shape({
			qry: PropTypes.string,
			actionURL:PropTypes.shape({
				url: PropTypes.string
			}),
			suggestions: PropTypes.shape({
				most_recent: PropTypes.array,
				most_watched: PropTypes.array
			})
		})
	})
};

export default SearchForm;
