import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './../scss/Footer.scss';

import { getRelativeUrl } from '../../util/lib';
import PrivacyCheck from './PrivacyCheck';
import FooterRequest from './request/FooterRequest';
import MouseFlow from './MouseFlow';

const FOOTER = 'ftr-',
	processFooterData = (data = {}) => {
		const widgets = data.widgets || data.widget || [],
			widgetsArray = Array.isArray(widgets) ? widgets : [widgets],
			footers = widgetsArray.filter((widget) => {
				return (widget.type === 'Footer');
			}, {}),
			footer = footers.length ? footers[0] : '',
			privacyChecks = widgetsArray.filter((widget) => {
				return (widget.type === 'PrivacyCheck');
			}, {}),
			privacyCheck = privacyChecks.length ? privacyChecks[0] : '';

		return {
			footer,
			privacyCheck,
		};
	},
	processFooterWidget = (footer = '') => {
		if (footer) {
			const data = footer.data || {},
				links = data.links || [],
				copyrightText = data.copyright_text || {};

			return { links, copyrightText }
		}
		return '';
	};

class Footer extends Component {
	render() {
		const footerData = processFooterData(this.props.footerData),
			footer = processFooterWidget(footerData.footer || ''),
			copyrightText = footer ? footer.copyrightText || {} : {},
			footerWidgets = this.props.footerData || {},
			widgets = footerWidgets.widgets || [],
			mouseflow = widgets.filter(widget => widget.type === "Mouseflow") || false;


		console.log("Simply South Footer Data ", mouseflow);

		return (<>
			{footer && <footer className="site-footer">
				<div className="all-links container d-flex flex-lg-row flex-column justify-content-between">
					{footer.links.map((item, index) => {
						const itemData = item.data || {},
							type = item.type || '',
							title = itemData.title,
							meta = item.meta,
							isExternalLink = !(!meta.isExternalLink),
							link = itemData.link || {},
							linkURL = isExternalLink ? link.url : getRelativeUrl(link.url);

						if (type === 'Link') {
							if (isExternalLink) {
								return (
									<a key={FOOTER + index} href={linkURL} target="_blank" rel="noopener noreferrer" className="d-inline-block">
										<span className="app-link">
											{title}
										</span>
									</a>
								);
							} else {
								return (
									<Link key={FOOTER + index} to={linkURL} className="d-inline-block">
										<span className="app-link">
											{title}
										</span>
									</Link>
								);
							}
						}
						return false;
					})}
					<span className='d-inline-block'>
						<span>&copy; <span className='domain-name'>{copyrightText.site_name || ''}</span> {copyrightText.year || ''} {copyrightText.text || ''} </span>
					</span>
				</div>
			</footer>}
			{!this.props.termsConsentCheck && footerData.privacyCheck && <PrivacyCheck widget={footerData.privacyCheck} />}
			{mouseflow && mouseflow.map((mf = {}, index = 0) => { 
				return (<MouseFlow key={FOOTER + index} data={mf} />);
			})}
		</>);
	}
}

export default FooterRequest(Footer);
