import React from 'react';
import { footerLayoutMap } from './footerLayoutMap';

function FooterLayoutFactory(props) {
	const layout = props.layout || 1,
		Layout = footerLayoutMap[layout]; //note this is an object and layout is keys
	return (
		Layout ? <Layout {...props} /> : <></> //note (Arun) added ternary condition because of major runtime error if layout was the wrong value
	);
}

export default FooterLayoutFactory
