import { Component } from 'react';
import { clearL, getL, setL } from '../../../util/lib';
import loadJSTag from '../../../util/loadJSTag';

let gaMeasurementIDMod;

const G4_LS_KEY = 'g4_tracker',
	GOOGLE_TAG_MANAGER = 'https://www.googletagmanager.com/gtag/js',
	initReactGA = (gaMeasurementID = '') => {
		loadJSTag(GOOGLE_TAG_MANAGER).then(() => {
			window.dataLayer = window.dataLayer || [];
			window.gtag = function() {
				window.dataLayer.push(arguments);
			};
			window.gtag('js', new Date());
			window.gtag('config', gaMeasurementID, {
				'transport_type': 'beacon'
			});
			gaMeasurementIDMod = gaMeasurementID;
		});
	};

class GA4Tracker extends Component {
	constructor(props) {
		const gaMeasurementID = props.gaMeasurementID || '';
		super(props);
		initReactGA(gaMeasurementID);
	}
	
	render () {
		return (null);
	}
}

export default GA4Tracker;

/** 
	* This method can be used to dispatch a GA4 'event' tracker
	* @param action The event action to be tracked. Deafult value is an empty string. 
	* @param category The category the event action will be groupt to. Deafult value is 'User'. 
	* @param value Additioanl value to be sent with the event.
	* @return undefined
*/
export function ga4Event(event = '', params = '', trackOnReload = false) {
	if (trackOnReload) {
		const store = {event, params}
		setL(G4_LS_KEY, store);
	} else {
		const ga4 = (window && window.ga4) ? window.ga4 || '' : '',
			gtag =  window.gtag || ga4.gtag || '';

		if (gtag) {
			gtag('event', event, params);
		}
	}
};

/** 
	* This method can be used to dispatch a GA4 'page view' tracker
	* @param path Pass the current page's path to track page view
	* @return undefined
*/
export function ga4PageView(path = '/') {
	
};