import React from 'react';
import Loadable from '@react-loadable/revised';

const widgetFactoryMap = {
	OrderListing: {
		type: Loadable({ loader: () => import('../OrderListing'), loading: () => <div></div> }),
		idPrefix: "odls-"
	},
	ShowHorizontalListing: {
		type: Loadable({ loader: () => import('../ShowHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "shhl-"
	},
	ShopPayment: {
		type: Loadable({ loader: () => import('../ShopPayment'), loading: () => <div></div> }),
		idPrefix: "shpmt-"
	},
	Error: {
		type: Loadable({ loader: () => import('../Error'), loading: () => <div></div> }),
		idPrefix: "er-"
	},
	ShopCheckoutForm: {
		type: Loadable({ loader: () => import('../ShopCheckoutForm'), loading: () => <div></div> }),
		idPrefix: "scf-"
	},
	ProductDetails: {
		type: Loadable({ loader: () => import('../ProductDetails'), loading: () => <div></div> }),
		idPrefix: "prdt-"
	},
	ProductGridListing: {
		type: Loadable({ loader: () => import('../ProductGridListing'), loading: () => <div></div> }),
		idPrefix: "pgl-"
	},
	VideoHorizontalListing: {
		type: Loadable({ loader: () => import('../VideoHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "vhl-",
		addFont: true
	},
	CategoryHorizontalListing: {
		type: Loadable({ loader: () => import('../CategoryHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "chl-",
		addFont: true
	},
	MovieHorizontalListing: {
		type: Loadable({ loader: () => import('../MovieHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "mhl-"
	},
	DescriptiveHorizontalListing: {
		type: Loadable({ loader: () => import('../DescriptiveHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "chl-"
	},
	VideoCarousel: {
		type: Loadable({ loader: () => import('../VideoCarousel'), loading: () => <div></div> }),
		idPrefix: "vc-"
	},
	VideoDetails: {
		type: Loadable({ loader: () => import('../VideoDetails'), loading: () => <div></div> }),
		idPrefix: "vd-",
		addFont: true,
		temp_other: true,
		setVideoDetails: true
	},
	LiveVideoDetails: {
		type: Loadable({ loader: () => import('../LiveVideoDetails'), loading: () => <div></div> }),
		idPrefix: "lvd_",
		addFont: true,
		temp_other: true,
		setVideoDetails: true
	},
	MovieDetails: {
		type: Loadable({ loader: () => import('../MovieDetails'), loading: () => <div></div> }),
		idPrefix: "md_",
		setVideoDetails: true
	},
	HybridDetailWidget: {
		type: Loadable({ loader: () => import('../hybrid/DetailsWidget'), loading: () => <div></div> }),
		idPrefix: "md_",
		setVideoDetails: true
	},
	PdfDetails: {
		type: Loadable({ loader: () => import('../PdfDetails'), loading: () => <div></div> }),
		idPrefix: "pdfd_"
	},
	VideoGridListing: {
		type: Loadable({ loader: () => import('../VideoGridListing'), loading: () => <div></div> }),
		idPrefix: "vgl_",
		addFont: true
	},
	MovieGridListing: {
		type: Loadable({ loader: () => import('../MovieGridListing'), loading: () => <div></div> }),
		idPrefix: "mgl_"
	},
	PdfGridListing: {
		type: Loadable({ loader: () => import('../PdfGridListing'), loading: () => <div></div> }),
		idPrefix: "pgl_"
	}, // id is mgl doubt ~ Arun's Note : chngs to pgl
	ShowGridListing: {
		type: Loadable({ loader: () => import('../ShowGridListing'), loading: () => <div></div> }),
		idPrefix: "sgl_"
	},
	CategoryGridListing: {
		type: Loadable({ loader: () => import('../CategoryGridListing'), loading: () => <div></div> }),
		idPrefix: "cgl_"
	},
	CardHorizontalListing: {
		type: Loadable({ loader: () => import('../CardHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "chl_"
	},
	LoginForm2: {
		type: Loadable({ loader: () => import('../Login'), loading: () => <div></div> }),
		idPrefix: "l_",
		passFullProps: true
	},
	LoginForm: {
		type: Loadable({ loader: () => import('../LoginForm'), loading: () => <div></div> }),
		idPrefix: "lf_",
		passFullProps: true
	},
	RegisterForm: {
		type: Loadable({ loader: () => import('../RegisterForm'), loading: () => <div></div> }),
		idPrefix: "lf_"
	},
	PlanListing: {
		type: Loadable({ loader: () => import('../PlanListing'), loading: () => <div></div> }),
		idPrefix: "lf_",
		passFullProps: true
	},
	UserProfile: {
		type: Loadable({ loader: () => import('../UserProfile'), loading: () => <div></div> }),
		idPrefix: "up-"
	},
	SearchForm: {
		type: Loadable({ loader: () => import('../SearchForm'), loading: () => <div></div> }),
		idPrefix: "schfrm-",
		onDataChange: true
	},
	Payment: {
		type: Loadable({ loader: () => import('../payment/Payment'), loading: () => <div></div> }),
		idPrefix: "pmt-"
	},
	CheckoutPayment: {
		type: Loadable({ loader: () => import('../payment/CheckoutPayment'), loading: () => <div></div> }),
		idPrefix: "co-pmt-"
	},
	MultiPlanPayment: {
		type: Loadable({ loader: () => import('../payment/MultiPlanPayment'), loading: () => <div></div> }),
		idPrefix: "mpp-"
	},
	PaymentConfirmation: {
		type: Loadable({ loader: () => import('../payment/PaymentConfirmation'), loading: () => <div></div> }),
		idPrefix: "pcf-"
	},
	DeviceConnect: {
		type: Loadable({ loader: () => import('../DeviceConnect'), loading: () => <div></div> }),
		idPrefix: "divc-"
	},
	SupportForm: {
		type: Loadable({ loader: () => import('../SupportForm'), loading: () => <div></div> }),
		idPrefix: "spfrm-"
	},
	MessageWidget: {
		type: Loadable({ loader: () => import('../MessageWidget'), loading: () => <div></div> }),
		idPrefix: "mswg-",
		passtype: true
	},
	NotFound: {
		type: Loadable({ loader: () => import('../MessageWidget'), loading: () => <div></div> }),
		idPrefix: "mswg-",
		passtype: true
	},
	FullPageMessageWidget: {
		type: Loadable({ loader: () => import('../FullPageMessageWidget'), loading: () => <div></div> }),
		idPrefix: "fpmw-"
	},
	FilterHorizontalWidget: {
		type: Loadable({ loader: () => import('../FilterHorizontalWidget'), loading: () => <div></div> }),
		idPrefix: "flhl-",
		onDataChange: true
	},
	HorizontalAdBanner: {
		type: Loadable({ loader: () => import('../HorizontalAdBanner'), loading: () => <div></div> }),
		idPrefix: "hab-"
	},
	ShowBanner: {
		type: Loadable({ loader: () => import('../ShowBanner'), loading: () => <div></div> }),
		idPrefix: "sb-"
	},
	CategoryBanner: {
		type: Loadable({ loader: () => import('../CategoryBanner'), loading: () => <div></div> }),
		idPrefix: "cb-"
	},
	EpgHorizontalListing: {
		type: Loadable({ loader: () => import('../EpgWidget'), loading: () => <div></div> }),
		idPrefix: "epgw-"
	},
	VideoVerticalListing: {
		type: Loadable({ loader: () => import('../TabbedVerticalVideoListing'), loading: () => <div></div> }),
		idPrefix: "tvvl-"
	},
	HybridGridListing: {
		type: Loadable({ loader: () => import('../hybrid/HybridGridListing'), loading: () => <div></div> }),
		idPrefix: "tvvl-"
	},
	CompositeEpgVerticalListing: {
		type: Loadable({ loader: () => import('../epg/CompositeEpgVerticalListing'), loading: () => <div></div> }),
		idPrefix: "cevl-"
	},
	AppLinksHorizontalListing: {
		type: Loadable({ loader: () => import('../AppLinksHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "alhl-"
	},
	ShowDetails: {
		type: Loadable({ loader: () => import('../ShowDetails'), loading: () => <div></div> }),
		idPrefix: "shwd-"
	},
	RSSWallApp: {
		type: Loadable({ loader: () => import('../3rdPartyWidgets/RSSWallApp'), loading: () => <div></div> }),
		idPrefix: "rwp-"
	},
	TabWidget: {
		type: Loadable({ loader: () => import('../TabWidget'), loading: () => <div></div> }),
		idPrefix: "tab-"
	},
	TicketVerticalListing: {
		type: Loadable({ loader: () => import('../TicketVerticalListing'), loading: () => <div></div> }),
		idPrefix: 'vertical-ticket-listing-'
	},
	HybridPosterWidget: {
		type: Loadable({ loader: () => import('../hybrid/HybridPosterWidget'), loading: () => <div></div> }),
		idPrefix: "hpw-",
		setVideoDetails: true,
	},
	HybridCarousel: {
		type: Loadable({ loader: () => import('../hybrid/HybridCarousel'), loading: () => <div></div> }),
		idPrefix: "hc-"
	},
	HybridHorizontalListing: {
		type: Loadable({ loader: () => import('../hybrid/HybridHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "hhl_"
	},
	HybridVerticalListing: {
		type: Loadable({ loader: () => import('../hybrid/HybridVerticalListing'), loading: () => <div></div> }),
		idPrefix: "hvl_"
	},
	HybridDetails: {
		type: Loadable({ loader: () => import('../hybrid/HybridDetails'), loading: () => <div></div> }),
		idPrefix: "hdl_"
	},
	HybridForm: {
		type: Loadable({ loader: () => import('../hybrid/HybridForm'), loading: () => <div></div> }),
		idPrefix: "hlf_",
		handlers: true
	},
	HybridTab: {
		type: Loadable({ loader: () => import('../hybrid/HybridTab'), loading: () => <div></div> }),
		idPrefix: "hlf_",
		handlers: true
	},
	HybridLinkWidget: {
		type: Loadable({ loader: () => import('../hybrid/footer/HybridLinkWidget'), loading: () => <div></div> }),
		idPrefix: "hlw_",
	},
	SocialShare: {
		type: Loadable({ loader: () => import('../social/SocialShare'), loading: () => <div></div> }),
		idPrefix: "ss_",
	},
	DeleteMyAccount: {
		type: Loadable({ loader: () => import('../DeleteMyAccount/DeleteMyAccount.js'), loading: () => <div></div> }),
		idPrefix: "dmacc",
	},
};

export default widgetFactoryMap;