import React, { Component } from 'react';

import { HeaderContext } from './HeaderContext';
import { getUrlFromBaseAPI, getSettingsAPI, getAPIData } from '../../../util/lib';

class HeaderManager extends Component {
	constructor (props) {
		super(props);

		this.unLoad = () => {
			if (this.state.widget) {
				this.setState({
					widget: '',
				});
			}
		};
		this.load = () => {
			
			const navBarURL = getUrlFromBaseAPI('nav_bar');
	
			getSettingsAPI()
				.then((settings) => {
					this.setState({settingsData: settings}, () => {
						if (navBarURL) {
							getAPIData(navBarURL, {}, (response = {}) => {
								const data = response.data || {},
									widget = data.widget || {};
			
								if (widget.type === 'NavBar') {
									const meta = widget.meta || {},
										data = widget.data || {},
										siteLogo = this.state.siteLogo || data.site_logo || {};

									this.setState({
										siteLogo,
										widget,
										canShowShoppingCartYN: !(!meta.canShowShoppingCartYN)
									});
								}
							});
						}
					});
				}).catch(() => {
					console.error("Unable to get Settings Data");
				});
		};
		this.hide = () => {
			const hideCount = this.state.hideCount += 1;
			this.setState({hideCount})
		}
		this.setPageMeta = (pageMeta = '') => {
			if (pageMeta !== this.state.pageMeta) {
				this.setState({pageMeta});
			}
		};
		this.setPath = (page = '') => {
			if (page !== this.state.page) {
				this.setState({page});
			}
		}

		this.setPageLoading = (val = false) => {
			const pageLoading = !(!val);

			if (pageLoading !== this.state.pageLoading) {
				this.setState({pageLoading});
			}
		}
		
		this.state = {
			isWebview: props.isWebview,
			page: props.page || '',
			pageMeta: {},
			videoDetails: {}, 
			siteLogo: '',
			widget: {},
			settingsData: '',
			canShowShoppingCartYN: false,
			hideCount: 0,
			pageLoading: false,
			setPath: this.setPath,
			setPageLoading: this.setPageLoading,
			setPageMeta: this.setPageMeta,
			unLoad: this.unLoad,
			load: this.load,
			hide: this.hide
		}
	}
	

	render () {
		return (
			<HeaderContext.Provider value={this.state}>
				{this.props.children}
			</HeaderContext.Provider>
		);
	}
}

export default HeaderManager
