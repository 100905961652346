import React from 'react';

import './scss/SocialLinksJ.scss';
import { Link } from 'react-router-dom';
import { getRelativeUrl } from '../../../util/lib';

const SocialLinksJ = (props = {}) => {
    const settingsData = props.settingsData || {},
        logo = settingsData.logo || '',
        widget = props.widget || {},
		data = widget.data || {},
        links = data.links || '',
        siteLogoObj = data.site_logo || {},
		siteLogoData = siteLogoObj.data || {},
		siteLogoLink = siteLogoData.link || {},
		siteLogoURL = siteLogoLink.url || '';	
		
	return (<div className="social-links container bottom-line">
			<div className="all-links d-flex flex-sm-row row">
				<div className="col-6 col-md-4">
					{siteLogoURL && <Link to={getRelativeUrl(siteLogoURL)} className="app-logo">
						<img src={logo} className="app-logo" alt="logo"/>
					</Link>}
				</div>
				<div className="col-6 col-md-8">
					{Array.isArray(links) && links.map((item = {}, index) => {
						const iData = item.data || {},
							linkObj = iData.link || {},
							linkURL = linkObj.url || '',
							target = linkObj.target || '_self',
							icon = iData.icon || 'dflt';

						if (item.type === 'Link') {
							return (
								<a key={'nv' + index} href={linkURL} target={target} className={'icon-link social-icon ' + icon} />
							);
						}
						return false;
					})}
				</div>
			</div>
		</div>);
};

export default SocialLinksJ;