import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './../scss/EmptyCartCard.scss';

import EmptyCart from '../svg/EmptyCart/EmptyCart';


class EmptyCartCard extends Component {
	render () {
		const card = this.props.card,
			data = card.data || {},
			labels = data.labels || {},
			backMsg = labels.back_message || {},
			backLinkObj = data.back_link || {},
			backLinkPath = backLinkObj.path || '';


		console.log(data);
																																																				
		return (
			<div className="v-card empty-cart-card">
				<div className="empty-cart-grf">
					<EmptyCart></EmptyCart>
				</div>
				<div className="empty-cart-msg">{labels.empty_cart_message || ''}</div>
				{(backMsg && backLinkPath) && <div className="cart-links">
					<div className="cart-links-msg">
						<Link to={backLinkPath}><span>{backMsg}</span></Link>
					</div>	
				</div>}
			</div>
		);
	}
}

export default EmptyCartCard;