import React, { Component } from 'react';

import "./../scss/PrivacyCheck.scss";
import { getAPIData, getL } from '../../util/lib';

class PrivacyCheck extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active: true
		};

		this.onAcceptClick = this.onAcceptClick.bind(this);
	}

	componentDidMount() {

		this.setState({});
		/*this.setState({
			terms:this.props.privacycheck.data.terms_link.path || "",
			privacy:this.props.privacycheck.data.privacy_link.path || "",
			terms_label:this.props.privacycheck.data.labels.terms_label || "",
			privacy_label:this.props.privacycheck.data.labels.privacy_label || "",
			agree_msg:this.props.privacycheck.data.labels.agree_message|| "",
			personal_data_msg:this.props.privacycheck.data.labels.personal_data_message || "",
		})*/
	}

	onAcceptClick() {
		const did = getL('did'),
			widget = this.props.widget || {},
			data = widget.data || {},
			consentLinkObj = data.update_terms_consent_link || {},
			consentLink = consentLinkObj.url || "";

		if (consentLink) {
			getAPIData(consentLink, { did }, () => {
				this.setState({ active: false });
			});
		}
	}

	render() {
		const active = !(!this.state.active),
			widget = this.props.widget || {},
			data = widget.data || {},
			labels = data.labels || {},
			termLabel = labels.terms_label || '',
			privacyLabel = labels.privacy_label || '',
			termsLinkObj = data.terms_link || {},
			termsLink = termsLinkObj.path || '',
			privacyLinkObj = data.privacy_link || {},
			privacyLink = privacyLinkObj.path || '';
		console.log("pc:", this.props);
		return (
			<div className="privacy_check" style={{ display: (active ? 'block' : 'none') }}>
				<div className="container-fluid">
					<div className="row" >
						<div className="col-11 col-sm-9 col-lg-10">
							<div className="label-hldr">
								{((labels.agree_message || '').replace((termLabel), ' ')).replace('.', '')}<a href={termsLink}>{termLabel}</a>
								{'. '}
								{((labels.personal_data_message || '').replace((privacyLabel), ' ')).replace('.', '')}<a href={privacyLink}>{privacyLabel}.</a>
							</div>
						</div>
						<div className="col-2 col-sm-3 col-lg-2 text-right">
							<div>
								<button type="button" className="btn vt-btn-primary btn-lg vt-btn vt-btn-lg" onClick={this.onAcceptClick} >Accept</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PrivacyCheck;