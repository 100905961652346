import React from 'react';

export const ShoppingCartContext = React.createContext({
	shopEnabled: false,
	isOpen: false,
	animateIcon: false,
	items: 0,
	cartContent: {},
	open: () => {},
	close: () => {},
	modifyCart: () => {},
	refreshCart: () => {}
});