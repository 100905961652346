import React, { Component } from 'react';

import { JUPITER, EARTH, VENUS, MARS } from './ThemeWrapper';

const getClassName = (id = 1) => {
	return (id === 3) ? JUPITER : (id === 2) ? EARTH : (id === 4) ? MARS : VENUS;
},
	ClassWrapper = (Comp) => {
		class ClassWrapperComp extends Component {
			componentDidMount() {
				if (typeof window !== 'undefined') {
					const doc = window.document || '',
						className = getClassName(parseInt(this.props.themeID || 1));

					if (doc) {
						const body = doc.querySelector('body');

						body.className = className;
					}
				}
			}

			render() {
				const props = this.props,
					className = getClassName(parseInt(props.themeID || 1));

				return (<Comp {...{ className, ...props }}>

				</Comp>);
			}
		};

		return ClassWrapperComp;
	};

export default ClassWrapper;