import React from 'react'

function ComingSoon() {
    return (
        <div>
            <h3>Coming soon</h3>
        </div>
    )
}

export default ComingSoon
