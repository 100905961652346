import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './../scss/CartSummaryCard.scss';

import PropTypes from 'prop-types';

class CartSummaryCard extends Component {
	static propTypes = {
		cards : PropTypes.shape({
			layout: PropTypes.number,
			type: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string,
				cart_subtotal: PropTypes.string,
				cart_subtotal_formated: PropTypes.string,
				cart_shipping: PropTypes.string,
				cart_shipping_formated: PropTypes.string,
				cart_shipping_msg: PropTypes.string,
				cart_total: PropTypes.string,
				cart_total_formated: PropTypes.string,
				cart_count: PropTypes.string,
				cart_currency_id: PropTypes.string,
				checkout_link: PropTypes.shape({
					path: PropTypes.string,
					url: PropTypes.string
				}),
				labels: PropTypes.shape({
					checkout_message: PropTypes.string,
					cart_subtotal_label: PropTypes.string,
					cart_shipping_label: PropTypes.string,
					cart_total_label: PropTypes.string
				}),
			}),
			meta: PropTypes.shape({
				cartYN: PropTypes.bool
			}),
		})
	}

	render() {
		const props = this.props,
			card = props.card,
			data = card.data || {},
			meta = card.meta || {},
			
			isCart = meta.cartYN || false,
			
			title = data.title || '',
			costSubTotal = data.cart_subtotal_formated || '',
			costTotal = data.cart_total_formated || '',
			shippingTotal = data.cart_shipping_msg || data.cart_shipping_formated || '',

			checkoutObj = data.checkout_link || {},
			checkoutPath = checkoutObj.path || '',
			

			labels = data.labels || '',
			checkoutMsg = labels.checkout_message || '',
			subTotalMsg = labels.cart_subtotal_label || '',
			shippingMsg = labels.cart_shipping_label || '',
			totalMsg = labels.cart_total_label || '';

		console.log(shippingTotal);

		return (
			<div className="v-card cart-summary-card">
				<div className="container">
					<div className="row summary-wrpr">
						<div className="col-3"/>
						<div className="col-9">
							<div className="container summary-top-pad">
								<div className="row">
									<div className="col-6 summary-content">{subTotalMsg}</div>
									<div className="col-6 summary-content summary-right">{costSubTotal}</div>
								</div>
								{(shippingTotal && shippingMsg) && <div className="row">
									<div className="col-6 summary-content">{shippingMsg}</div>
									<div className="col-6 summary-content summary-right">{shippingTotal}</div>
								</div>}
								<div className="row summary-border" />
								<div className="row">
									<div className="col-6 summary-total">{totalMsg}</div>
									<div className="col-6 summary-total summary-right">{costTotal}</div>
								</div>
							</div>
						</div>
					</div>
					{isCart && checkoutPath && <div className="sh-cart-btn">
						<Link to={checkoutPath} className='btn btn-lg vt-btn vt-btn-primary'>{checkoutMsg}</Link>
					</div>}
				</div>
			</div>
		);
	}
}

export default CartSummaryCard;