import { post } from './fetchRequest.js';
import { getEnvVar, getL, getLC, getUrlFromBaseAPI, parseAPIResponse, setUserDetails } from './lib.js';

export const sessionStrategy = ['localstore', 'cookie'];
export const publicRoutes = ['/login', '/register', '/base', '/manifest', '/undefined'];


const DEFAULT_TOKEN_EXPIRY_TIME = 3600; //in seconds (1 hour);

//module level variables 
let refreshAuthTokenApi = '';

export const cacheRefteshTokenApi = () => {
    refreshAuthTokenApi = refreshAuthTokenApi ? refreshAuthTokenApi : getUrlFromBaseAPI('refresh_token') || '';
}
export const createUserSession = (strategy, data) => {
    if (sessionStrategy.includes(strategy)) {
        switch (strategy) {
            case 'localstore':
                if (data.token && data.refreshToken) {
                    data.timestamp = new Date();
                    data.expires_in = getEnvVar('REACT_APP_JWT_EXPIRY_TIME') || DEFAULT_TOKEN_EXPIRY_TIME;
                    console.log("expiry time:", data.expires_in);
                    localStorage.setItem('vt-auth-token', JSON.stringify(data));
                    return true;
                } else {
                    console.log('Error: Token or refresh token Not available');
                    return false;
                }
                break;
            case 'cookie':
                if (data.token && data.refreshToken) {
                    // Cookies.set('user', JSON.stringify(data));
                    //todo implement cookie strategy
                }
                break;
            default:
                console.log('Error: Invalid strategy');
        }
    } else {
        console.log('Invalid session strategy');
    }
}
export const preserveUserData = ({ data }) => {
    if (data) {
        console.log("userrrr:data:", JSON.stringify(data));
        setUserDetails(data);
    }
}

export const clearUserData = () => {
    localStorage.removeItem('user');
}

export const authRedirection = (redirectionObj) => {
    console.log('Redirection object', redirectionObj);
    const active_purchase_flow_plan_id = getL('payment_plan_id'),
        deviceConnectFlow = getL('deviceConnectFlow'),
        postLoginRedirectUrl = getL('postLoginRedirectUrl'),
        destinationPagePath = getL('destination_page_path'),
        redirectURL = redirectionObj.path || '';

    if (redirectURL) {
        console.log('Redirecting to', redirectURL);
        window.location.href = redirectURL;
    } else if (deviceConnectFlow) {
        console.log('Redirecting to device connect flow');
        window.location.href = '/connect';
    } else if (active_purchase_flow_plan_id) {
        console.log('Redirecting to payment flow');
        window.location.href = '/payment';
    } else if (postLoginRedirectUrl) {
        console.log('Redirecting to post login flow');
        window.location.href = postLoginRedirectUrl;
    } else if (destinationPagePath) {
        console.log('Redirecting to destination page');
        window.location.href = destinationPagePath;
    } else {
        console.log('Redirecting to home page');
        window.location.href = '/';
    }
}

export const isSecureLogin = () => {
    const vt_auth_token = getL('vt-auth-token') || {};
    if (vt_auth_token.token) {
        console.log('Secure login:', vt_auth_token);
        return true;
    }
    console.log('no Secure login:', vt_auth_token);
    return false;
}

export const isTokenAlive = () => {
    const vt_auth_token = getL('vt-auth-token') || {};
    if (!vt_auth_token.token) {
        return false;
    }
    if (vt_auth_token.timestamp) {
        const currentTime = new Date(),
            tokenTime = new Date(vt_auth_token.timestamp);
        console.log("diff:", currentTime - tokenTime);
        console.log("exp in:", vt_auth_token.expires_in * 1000);
        console.log("isexp:", currentTime - tokenTime < (vt_auth_token.expires_in * 1000));

        return (currentTime - tokenTime < (vt_auth_token.expires_in * 1000))
    }
}

export const clearTokens = () => {
    localStorage.removeItem('vt-auth-token');
}

export const refreshAuthToken = () => {

    return new Promise((resolve, reject) => {
        try {
            console.log('Refreshing token:', refreshAuthTokenApi);
            const vt_auth_token = getL('vt-auth-token') || {};
            refreshAuthTokenApi = refreshAuthTokenApi ?
                refreshAuthTokenApi : (getUrlFromBaseAPI('refresh_token') || getLC('refresh-token') || "");
            if (vt_auth_token.refreshToken && vt_auth_token.token) {
                console.log('Refreshing token: all tokend available');
                const postData = {
                    app_version: getEnvVar('REACT_APP_API_VER') || '2',
                    token: vt_auth_token.token,
                    refresh_token: vt_auth_token.refreshToken
                };
                if (refreshAuthTokenApi) {
                    console.log('refreshAuthTokenApi url available');
                    post(refreshAuthTokenApi, postData).then((response = {}) => {
                        const parsedResponse = parseAPIResponse(response);
                        console.log('Refresh token response:', parsedResponse);
                        if (parsedResponse.error) {
                            reject({
                                status: false
                            });
                        } else if (parsedResponse.data) {
                            const { token = '', refresh_token = '' } = parsedResponse.data || {};
                            if (token && refresh_token) {
                                resolve({
                                    status: true,
                                    message: 'Token refreshed successfully',
                                    token,
                                    refresh_token
                                });
                            } else {
                                console.log('Error: Token or refresh token Not available');
                                // window.location.href = '/login';

                                reject({
                                    status: false,
                                });
                            }
                        }
                    }).catch((exception = '') => {
                        console.log('refresh token post:', exception);
                    });
                } else {
                    console.log("not a valid refresh token url, please check base api store");
                    reject({
                        status: false
                    });
                }
            }
        }
        catch (exception) {
            console.log('refreshAuthToken  Failed:', exception);
            reject(exception)
        }
    });
}


export const isToeknAvailable = (path) => {
    const vt_auth_token = getL('vt-auth-token') || {};
    if (vt_auth_token.token && vt_auth_token.refreshToken) {
        console.log('Token available');
        return true;
    }
    console.log('Token not available');
    return false;
}

export const LogoutUser = () => {
    console.log('Logout user');
    clearTokens();
    clearUserData();
}