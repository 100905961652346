import React, { Component, Fragment } from 'react';
import { getAPIData } from '../../../util/lib';
import ThemeWrapper from './ThemeWrapper';
import widgetFactoryMapper from './widgetFactoryMapper';

class WidgetFactory extends Component {
	state = {
		nextAPI: '',
		pageCount: 0
	}

	componentDidUpdate() {
		this.loadMoreWidgets(this.props.nextAPI || '');
	}

	loadMoreWidgets(nextAPI = '') {
		const state = this.state;

		if (nextAPI !== state.nextAPI) {
			const pageCount = parseInt(this.props.pageCount) || state.pageCount || 0;

			this.setState({ nextAPI, pageCount }, () => {
				if (nextAPI) {
					getAPIData(nextAPI, { deferred_load_supported: true }, (response = {}) => {
						const props = this.props,
							data = response.data || {},
							widgets = data.widgets || [];

						if (pageCount === this.state.pageCount && props.onDataChange) {
							props.onDataChange([...(props.widgets || []), ...widgets], data.next_url || '');
						}
					});
				}
			});
		}
	}

	render() {
		const props = this.props,
			widgets = props.widgets || [];
		// theme = props.theme || '',

		// Arun's note : formated to make it more readable 
		return (<Fragment>
			{widgets.map((item = {}, index) => {
				const type = item.type || '';
				return widgetFactoryMapper(type, {
					key: type + index,
					id: index,
					data: item,
					isInPopup: props.isInPopup || false
				}, {
					addFont: props.addFont,
					temp_other: widgets,
					setVideoDetails: props.setVideoDetails,
					onDataChange: props.onDataChange,
					fullProps: { ...props },
					handlers: props.handlers
				});
			})}
		</Fragment>);
	}
};

export default ThemeWrapper(WidgetFactory);


