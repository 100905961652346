const OPTIONS = {
		method: 'POST',
		mode: 'cors'
	},
	SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG!',
	convertToFormData = (data = {}) => {
		try {
			const urlSearchParams = new URLSearchParams();
			let entry;
			for (entry in data) {
				urlSearchParams.append(entry, data[entry]);
			}
			return urlSearchParams;
		} catch (exception) {
			console.log(SOMETHING_WENT_WRONG);
		}
	};

export const post = (url = '', data = {}) => {
	return new Promise((resolve, reject) => {
		fetch(url, {
			...OPTIONS,
			body: convertToFormData(data)
		}).then((response = {}) => {
			if (response.status == 200) {
				response.json().then((data = {}) => {
					resolve(data);
				}).catch((exception = '') => {
					reject(exception);
				});
			} else {
				reject(response.status);
			}
		}).catch((exception = '') => {
			reject(exception);
		});
	});
};



	