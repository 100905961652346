import React from 'react';

import '../../scss/earth/FooterLinksE.scss';
import { NavLink } from 'react-router-dom';
import { getRelativeUrl, clearUserDetails } from '../../../util/lib';
import { ga4Event } from '../trackers/GA4Tracker';

const processData = (data = {}) => {
		const linkGroups = data.link_groups || data.link_group || [],
			linkGroupsArray = Array.isArray(linkGroups) ? linkGroups : [linkGroups];
		
		return linkGroupsArray.map((linkGroup = {}) => {
			const rawLinks = linkGroup.links || [],
				links = Array.isArray(rawLinks) ? rawLinks : [rawLinks],
				linksLength = links.length,
				mid = links.length / 2,
				twoColum = links.length > 6; 

			return {
				title: linkGroup.title || '',
				twoColum,
				links: twoColum ? links.slice(0, mid) : links,
				moreLinks: links.slice(mid)
			}
		});
	},
	getLinks = (item = {}, index) => {
		const iData = item.data || {},
			meta = item.meta || {},
			linkObj = iData.link || {},
			linkURL = getRelativeUrl(linkObj.url || ''),
			icon = (typeof iData.icon === 'string') ? iData.icon || 'dflt' : 'dflt',
			textSize = iData.text_size || '',
			title = iData.title || '';

		console.log(iData);

		if (item.type === 'Link') {
			if (title.indexOf('Log Out') !== -1) {
				return (
					<li key={'nv' + index} className={'nav-item' + (textSize ? ' ' + textSize : '')}>
						<a className={(meta.showIconYN ? icon + ' icon-link' : '')} onClick={() => {
							ga4Event('logout', {method: 'Ventuno'});
							clearUserDetails();
							window.location.reload(true);
						}}>{title}</a>
					</li>
				)	
			} else {
				return (
					<li key={'nv' + index} className={'nav-item' + (textSize ? ' ' + textSize : '')}>
						<NavLink to={linkURL} className={(meta.showIconYN ? icon + ' icon-link' : '')}>
							{title}
						</NavLink>
					</li>
				);
			}
		}
		return false;
	},
	FooterLinksE = (props = {}) => {
		const widget = props.widget || {},
			data = widget.data || {},
			linkGroups = processData(data);
			

		return (<div className="footer-links container bottom-line">
			<div className="row">
				{linkGroups.map((linkGroup = {}, index) => {
					const title = linkGroup.title,
						twoColum  = linkGroup.twoColum,
						ulClassName = 'all-links' + (twoColum ? ' two-clm' : '');

					return (<div key={'nv' + index} className={'col-sm-' + (twoColum ? 6 : 3) + ' col-' + (twoColum ? 12 : 6)}>
						<div className="widget-title">
							<h2>{title || ''}</h2>
							<ul className={ulClassName}>
								{linkGroup.links.map(getLinks)}
							</ul>
							{twoColum && <ul className={ulClassName}>
								{linkGroup.moreLinks.map(getLinks)}
							</ul>}
						</div>
					</div>);
				})}
			</div>
		</div>);
	};

export default FooterLinksE;