import React from 'react';

import { HeaderContext } from './HeaderContext';
import ThemeWrapper, { EARTH } from '../factory/ThemeWrapper';

import Header from './Header';
import HeaderE from '../earth/HeaderE';
import PageLoaderAnimation from '../ui/PageLoaderAnimation';

let loaded = false;

const HeaderMaster = (props = {}) =>  {	
	return (
		<HeaderContext.Consumer>
			{({page, isWebview, pageLoading, load, siteLogo, widget, pageMeta, videoDetails, settingsData, canShowShoppingCartYN, hideCount}) => {
				const theme = props.theme || '',
					HComp = (theme === EARTH) ? HeaderE : Header;

				if (!loaded) {
					loaded = true;
					load();
				}

				return (<>
					{!isWebview && siteLogo && <HComp page={page} settingsData = {settingsData} siteLogo={siteLogo} header={widget} pageMeta={pageMeta} videoDetails={videoDetails} canShowShoppingCartYN={canShowShoppingCartYN} hideCount={hideCount}/>}
					{pageLoading && <PageLoaderAnimation />}
				</>);
			}}
		</HeaderContext.Consumer>
	);
}

export default ThemeWrapper(HeaderMaster);

