/**
	*The colorUtil.js is a webpack module containing private and public methods 
	* to convert and manuplate color values
	* @author Arun Mukkath
	* @version 1.0.0
	* @since 2019-10-25
*/

const singleDigHexa2doubleDig = (hexa) => {
	return (hexa.length === 1) ? '0' + hexa : hexa
};

const cleanHexa = (hexaString = '') => {
	const cleanHexaStr = String(hexaString || '').replace(/#/g, '').toUpperCase();
	
	if (cleanHexaStr.length >= 6) {
		return cleanHexaStr.substr(0, 6);
	} else if (cleanHexaStr.length >= 3) {
		const h = cleanHexaStr.substr(0, 3),
			r = '' + h[0] + h[0],
			g = '' + h[1] + h[1],
			b = '' + h[2] + h[2];
		
		return r + g + b; 
	} else {
		return '000000';
	}
};

const percentage2Hexa = (per = 0) => {
	const cleanPer = Math.min(Math.max(parseFloat(per) || 0, 0), 1),
		hexa = parseInt(cleanPer * 255).toString(16).toUpperCase();

	return singleDigHexa2doubleDig(hexa);
};

/**
	The below methods are based on the post 'Converting Color Spaces in JavaScript' from css-tricks.com
	https://css-tricks.com/converting-color-spaces-in-javascript/
*/

//HEX to RGB 

const hex2rgb = (hexa = '') => { 
	const h = cleanHexa(hexa),
		r = "0x" + h[0] + h[1],
		g = "0x" + h[2] + h[3],
		b = "0x" + h[4] + h[5];
	
	return {
		r: +r,
		g: +g,
		b: +b
	};
};

const rgb2hex = (r, g, b) => {
	const dflt = '00',
		hexR = singleDigHexa2doubleDig(r.toString(16) || dflt),
		hexG = singleDigHexa2doubleDig(g.toString(16) || dflt),
		hexB = singleDigHexa2doubleDig(b.toString(16) || dflt);
	//(hexa.length === 1) ? '0' + hexa : hexa;
	return '' + hexR + hexG + hexB;
};

const rgb2hsl = (r = 0, g = 0, b = 0) => {
	const rFrac = r / 255,
		gFrac = g / 255,
		bFrac = b / 255,
		cmin = Math.min(rFrac, gFrac, bFrac),
		cmax = Math.max(rFrac, gFrac, bFrac),
		delta = cmax - cmin;
	
	var h = 0, l = 0, s = 0;

	// ~ Calculate hue ~

	if (delta === 0) { // No difference
		h = 0;
	} else if (cmax === rFrac) { // Red is max
		h = ((gFrac - bFrac) / delta) % 6;
	
	} else if (cmax === gFrac) { // Green is max
		h = (bFrac - rFrac) / delta + 2;
	} else {// else Blue is max
		h = (rFrac - gFrac) / delta + 4;
	}

	h = Math.round(h * 60);
		
	// Make negative hues positive behind 360°
	if (h < 0) {
		h += 360;	
	}

	// Calculate lightness
	l = (cmax + cmin) / 2;

	// Calculate saturation
	s = (delta === 0) ? 0 : delta / (1 - Math.abs(2 * l - 1));
		
	// Multiply l and s by 100
	s = + (s * 100).toFixed(1);
	l = + (l * 100).toFixed(1);
	
	return {h, s, l};//"hsl(" + h + "," + s + "%," + l + "%)";
};

const hsl2rgb = (h,s,l) => {
	const hue = h,
		sFrac = s / 100,
		lFrac = l / 100,
		c = (1 - Math.abs(2 * lFrac - 1)) * sFrac,
		x = c * (1 - Math.abs((hue / 60) % 2 - 1)),
		m = lFrac - c / 2;
	
	var r = 0, g = 0, b = 0;

	if (0 <= hue && hue < 60) {
		r = c;
		g = x;
		b = 0;
	} else if (60 <= hue && hue < 120) {
		r = x;
		g = c;
		b = 0;
	} else if (120 <= hue && hue < 180) {
		r = 0;
		g = c;
		b = x;
	} else if (180 <= hue && hue < 240) {
		r = 0;
		g = x;
		b = c;
	} else if (240 <= hue && hue < 300) {
		r = x;
		g = 0;
		b = c;
	} else if (300 <= hue && hue < 360) {
		r = c;
		g = 0;
		b = x;
	}
	
	return {
		r: Math.round((r + m) * 255),
		g: Math.round((g + m) * 255),
		b: Math.round((b + m) * 255)
	};
};

// ~public methods~

/** 
	* This method can be used to add alpha to a 'RRGGBB' hexa color string, thus converting it from RRGGBB to RRGGBBAA format
	* @param rgbHexaString Pass hexa RGB string (RGB, #RGB, RRGGBB or #RRGGBB format). Default value is '000000'. 
	* @param alhpa Pass alpha percentage in decimal value between 0 and 1 (e.g. 0.5 for 50%). Default value is 1.
	* @return String This returns modified color value as RRGGBBAA string
*/
export const rgbHex2rgbaHex = (rgbHexaString = '000000', alhpa = 1) => {
	const cleanRgbHexaString = cleanHexa(rgbHexaString),
		percentageHexa = percentage2Hexa(alhpa);

	return cleanRgbHexaString + percentageHexa;
};

/**     
	* This method can be used to increase or decrease lightness for a given color in 'RRGGBB' hexa string
	* @param rgbHexaString Pass hexa RGB string (RGB, #RGB, RRGGBB or #RRGGBB format). Default value is '000000'.
	* @param lightness is the percentage by wich to increase or decrease lightness form the color. The values can range between -100 to 100. Positive values will increase lightness and negative will decrease. Default value is 0.
	* @return String This returns modified color value as RRGGBB string
*/
export const changeColorLightness = (rgbHexaString = '000000', lightness = 0) => {
	const chngLPer = Math.min(Math.max(lightness, -100), 100),
		{r, g, b} = hex2rgb(rgbHexaString),
		{h, s, l} = rgb2hsl(r, g, b),
		newL = Math.min(Math.max(l + chngLPer, 0), 100);

	return ((h, s, l) => {
		const {r, g, b} = hsl2rgb(h, s, l);
		return rgb2hex(r, g, b);
	})(h, s, newL);
};