/**
	*The colorUtil.js is a webpack module containing private and public methods 
	* to convert and manuplate color values
	* @author Arun Mukkath
	* @version 1.0.0
	* @since 2019-11-22
*/

import ReactPixel from 'react-facebook-pixel';
import { clearL, getL, setL } from './lib';

const CUSTOM = 'custom',
	STANDARD = 'standard',
	//FBPixel local methods
	fbpTrack = (event = '', tracker = STANDARD) => {
		if (window) {
			if (window.ReactPixel) {
				ReactPixel[(tracker === CUSTOM) ? 'track' : 'trackCustom'](event);
			}
			if (window.fbq) {
				if (tracker === CUSTOM) {
					
				}
			}
		}
	};

// ~public methods~

/** 
	* This method can be used to dispatch a FB Pixel tracker for a given event. 
	* @param event Pass standard or custome FB Pixel event. Deafult value is an empty string. 
	* @param tracker Pass the type of traker to be used. The two valis options are "standard" & "custom". Default option is "standard"
	* @return undefined
*/
export function pixelTrack(event, tracker) {
	fbpTrack(event, tracker);
};

/** 
	* This method can be used to dispatch a FB Pixel 'page view' tracker
	* @return undefined
*/
export function fbPixelPageView() {
	fbpTrack('PageView');
};

/** 
	* This method can be used to dispatch a GA 'page view' tracker
	* @param path Pass the current page's path to track page view
	* @return undefined
*/
export function gaPageView(path = '/') {
	const ReactGA = (window && window.ReactGA) ? window.ReactGA || '' : '';

	if (ReactGA) {
		ReactGA.pageview(String(path));
	}
};

/** 
	* This method can be used to dispatch a GA 'event' tracker
	* @param action The event action to be tracked. Deafult value is an empty string. 
	* @param category The category the event action will be groupt to. Deafult value is 'User'. 
	* @param value Additioanl value to be sent with the event.
	* @return undefined
*/
export function gaEvent(action = '', category = 'User', value = '') {
	const ReactGA = (window && window.ReactGA) ? window.ReactGA || '' : '';

	if (ReactGA) {
		ReactGA.event({category, action, value});
	}
};

