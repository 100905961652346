import React, { Component } from 'react';

import '../../scss/earth/FooterE.scss';
import PrivacyCheck from '../PrivacyCheck';
import FooterMenuE from './FooterMenuE';
import SocialLinksE from './SocialLinksE';
import FooterLinksE from './FooterLinksE';
import FooterRequest from '../request/FooterRequest';

const processFooterData = (data = {}) => {
	const widgetsRaw = data.widgets || data.widget || [],
		widgets = Array.isArray(widgetsRaw) ? widgetsRaw : [widgetsRaw];
			
	return widgets;
};

class FooterE extends Component {
	render() {
		const widgets = processFooterData(this.props.footerData);

		console.log(widgets);

		return (<footer className="site-footer">
			{widgets.map((widget = {}, index) => {	
				return (<span key={'ftrw' + index}>
					{(widget.type === 'PrivacyCheck') && !this.props.termsConsentCheck && <PrivacyCheck widget={widget} />}
					{(widget.type === 'SocialLinks') && <SocialLinksE key={'ftrw' + index} widget={widget} />}
					{(widget.type === 'FooterLinks') && <FooterLinksE key={'ftrw' + index} widget={widget} />}
					{(widget.type === 'FooterMenu') && <FooterMenuE key={'ftrw' + index} widget={widget} />}
				</span >);
			})}
		</footer>);
	}
}

export default FooterRequest(FooterE);