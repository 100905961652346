import { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';

const initReactPixel = (fbpTrackerId = '') => {
	ReactPixel.init(fbpTrackerId);
	console.log('%%%%%%%%%%%%%%%');
	console.log(fbpTrackerId);
	console.log('%%%%%%%%%%%%%%%');
	if (window) {
		window.ReactPixel = ReactPixel;
	}
}

class FBPixelTracker extends Component {
	constructor(props) {
		const fbpTrackerId = props.fbpTrackerId || '';
		super(props);
		initReactPixel(fbpTrackerId);
	}

	componentDidUpdate(prevProps) {
		const props = this.props,
			fbpTrackerId = props.fbpTrackerId || '';

		if (this.props.fbpTrackerId !== prevProps.fbpTrackerId) {
            initReactPixel(fbpTrackerId);
		}
	}
	
	render () {
		return (null);
	}
}

export default FBPixelTracker;