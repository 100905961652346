import React, { Fragment } from 'react';
import EarthMaster from '../earth/EarthMaster';
import JupiterMaster from '../jupiter/JupiterMaster';
import VenusMaster from '../venus/VenusMaster';
import MarsMaster from '../Mars/MarsMaster';

const MasterLoader = (props) => {
	const themeID = parseInt(props.themeID || 1);

	return (
		<Fragment>
			{(themeID === 1) && <VenusMaster />}
			{(themeID === 2) && <EarthMaster />}
			{(themeID === 3) && <JupiterMaster />}
			{(themeID === 4) && <MarsMaster />}
		</Fragment>
	);
};

export default MasterLoader;


