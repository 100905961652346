import React from 'react';

import '../../scss/venus/VenusMaster.scss';
import { Helmet } from 'react-helmet';
import ErrorBoundaryDefault from '../ErrorBoundaries/ErrorBoundaryDefault';

const VenusMaster = () => {

	return (
		<ErrorBoundaryDefault>
			<Helmet>
				<link href="https://fonts.googleapis.com/css?family=Montserrat:400,600" rel="stylesheet"></link>
			</Helmet>
		</ErrorBoundaryDefault>);
}

export default VenusMaster;