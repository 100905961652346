import React from 'react';

import './scss//MarsMaster.scss';
import { Helmet } from 'react-helmet';
import ErrorBoundaryDefault from '../ErrorBoundaries/ErrorBoundaryDefault';

const MarsMaster = () => {

	return (
		<ErrorBoundaryDefault>
			<Helmet>
				<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"></link>
			</Helmet>
		</ErrorBoundaryDefault>);
}

export default MarsMaster;